const DBToFloat = (levelref, levelcible) => {
  const temp = 10.0 ** ((levelcible - levelref) / 20.0);
  return temp;
};

const FloatToDB = (levelref, volume) => {
  const temp = 20 * Math.log10(volume) + levelref;
  return temp;
};

export default {
  DBToFloat,
  FloatToDB,
};
