import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import PropTypes from 'prop-types';
import platform from 'platform';
import Routes from 'utils/Routes';
import { Howl } from 'howler';
import { stepTonalHandler } from 'actions/steps';
import { setResultTonal, setTonalFinish } from 'actions/tonals';
import Main from 'containers/partials/Main';
import entendreHomeFilter from 'images/entendreHomeFilter.jpg';
import { Route, Switch } from 'react-router';
import files from './Files';

import TestTonalIntro from './TestTonalIntro';
import TestTonalPlayer from './TestTonalPlayer';

const getPlatform = (sound) => {
  const os = platform.os.family;
  switch (os) {
    case 'Android':
    case 'Windows Phone':
      return sound.android;

    case 'iOS':
      return sound.ios;

    default:
      return sound.desktop;
  }
};

class TestTonal extends React.Component {
  state = {
    dataReady: [],
    loaded: false,
  }


  componentWillMount() { // TODO : Force user to continue the test
    const { stepTonal } = this.props;
    this.handleRoute(stepTonal);
  }


  componentDidMount() {
    this.loadAllSound().then((data) => {
      this.setState({
        loaded: true,
        dataReady: data,
      });
    });
  }

  getLevelRef(sound) {
    const { earDevice } = this.props;
    const userPlatform = getPlatform(sound);
    switch (earDevice) {
      case 'headphones':
        return userPlatform.headphones;
      default:
        return userPlatform.earphones;
    }
  }

  loadSound = soundData => (new Promise((resolve) => {
    const howlFile = new Howl({
      src: [soundData.file],
      loop: true,
    });
    howlFile.on('load', () => {
      const sound = {
        howl: howlFile,
        levelRef: this.getLevelRef(soundData),
      };
      resolve(sound);
    });
  }));

  loadAllSound = async () => {
    const data1 = await this.loadSound(files[0]);
    const data2 = await this.loadSound(files[1]);
    const data3 = await this.loadSound(files[2]);

    return [data1, data2, data3];
  };

  handleRoute = (step) => {
    const { dispatch } = this.props;
    const route = Routes.getRouteTonal(step);
    dispatch(push(route));
  }

  handleClick = (newStep) => {
    const { dispatch } = this.props;

    this.handleRoute(newStep);
    if (newStep === true) {
      dispatch(setTonalFinish());
    } else {
      dispatch(stepTonalHandler({ hear: newStep.hear, nb: newStep.nb }));
    }
  }

  handleResults = (newStep, DB) => {
    const { dispatch, stepTonal } = this.props;
    // const { dataReady } = this.state;

    dispatch(setResultTonal(stepTonal.hear, stepTonal.nb, DB)); //eslint-disable-line
    this.handleClick(newStep);
  }


  render() {
    const { stepTonal } = this.props;
    const {
      dataReady,
      loaded,
    } = this.state;

    return (
      <Main backImg={entendreHomeFilter} backImgMobile={entendreHomeFilter}>
        <Switch>
          <Route
            path="/test-tonal/oreille-gauche/:id"
            render={() => (
              <TestTonalPlayer
                data={dataReady}
                loaded={loaded}
                onClick={this.handleResults}
                hear={stepTonal.hear}
                nb={stepTonal.nb}
              />)}
          />
          <Route
            path="/test-tonal/oreille-droite/:id"
            render={() => (
              <TestTonalPlayer
                data={dataReady}
                loaded={loaded}
                onClick={this.handleResults}
                hear={stepTonal.hear}
                nb={stepTonal.nb}
              />)}
          />
          <Route path="/test-tonal/intro" render={() => <TestTonalIntro loaded={loaded} onClick={this.handleClick} />} />
        </Switch>
      </Main>
    );
  }
}

TestTonal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  stepTonal: PropTypes.object.isRequired,
  earDevice: PropTypes.string.isRequired,
};

export default connect(state => ({
  stepTonal: state.step.stepTonal,
  earDevice: state.earDevice,
  // location: state.router.location,
}))(TestTonal);
