
export const FINISH_TUTO = 'FINISH_TUTO';

function setTutoDone() {
  return {
    type: FINISH_TUTO,
    payload: {
      tutoDone: true,
    },
  };
}

export function finishTuto() {
  localStorage.setItem('tutoDone', true);
  return setTutoDone();
}
