
export const SET_RESULTS = 'SET_RESULTS';
export const SET_FINISH_UNDERSTANDS = 'SET_FINISH_UNDERSTANDS';

function setResults(step, result) {
  return {
    type: SET_RESULTS,
    payload: {
      step,
      result,
    },
  };
}

function finishTestUnderstands() {
  return {
    type: SET_FINISH_UNDERSTANDS,
    payload: {
      understandsDone: true,
    },
  };
}

export function setResultUnderstand(step, result) {
  return setResults(step, result);
}

export function setUnderstandFinish() {
  return finishTestUnderstands();
}
