const getRouteTonal = (step) => {
  if (step === true) {
    return '/test-comprehension/intro';
  } if (step.hear === 'right') {
    return step.hear ? `/test-tonal/oreille-droite/${step.nb}` : '/test-tonal/intro';
  } if (step.hear === 'left') {
    return step.hear ? `/test-tonal/oreille-gauche/${step.nb}` : '/test-tonal/intro';
  }
  return '/test-tonal/intro';
};

const getRouteUnderstand = (step) => {
  if (step === true) {
    return '/resultats';
  } if (step.nb >= 1) {
    return `/test-comprehension/${step.nb}`;
  }
  return '/test-comprehension/intro';
};

const getInitialRoute = (tutoDone, tonalsDone, understandsDone, step) => {
  if (tutoDone) {
    if (tonalsDone) {
      if (understandsDone) {
        return '/resultats';
      }
      return getRouteUnderstand(step.stepUnderstand);
    }
    return getRouteTonal(step.stepTonal);
  }
  return false;
};

export default { getRouteTonal, getRouteUnderstand, getInitialRoute };
