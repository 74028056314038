import React from 'react';

const TurningPhone = props => (
  <svg viewBox="0 0 569.2 569.2" width="1em" height="1em" {...props}>
    <path d="M111.4 449.7c-4.3-9.7-15.6-14.2-25.4-9.9-9.7 4.3-14.2 15.6-9.9 25.4l8.7 19.9c-37.6-22.1-55-68.8-38.6-110.7 3.9-9.9-1-21.1-10.9-25-10-3.9-21.1 1-25 10.9-24.4 62.4 3.3 132.5 62 162.1l-22.5 9.8c-9.7 4.3-14.2 15.6-9.9 25.4 3.2 7.2 10.2 11.5 17.7 11.5 2.6 0 5.2-.5 7.7-1.6l52.5-23c7.8-3.5 13.9-9.8 17-17.8 3.1-8 2.9-16.7-.5-24.6l-22.9-52.4zM496.9 46.7l22.5-9.8c9.7-4.3 14.2-15.6 9.9-25.4-4.3-9.7-15.6-14.1-25.4-9.9l-52.4 23c-7.9 3.5-14 9.8-17.1 17.8-3.1 8-3 16.8.5 24.7l23 52.4c3.2 7.2 10.2 11.5 17.7 11.5 2.6 0 5.2-.5 7.7-1.6 9.7-4.3 14.2-15.6 9.9-25.4l-8.7-19.9c37.6 22.1 54.9 68.8 38.5 110.7-3.9 9.9 1 21.1 11 25 2.3.9 4.7 1.3 7 1.3 7.7 0 15-4.6 18-12.3 24.2-62.4-3.5-132.5-62.1-162.1z" />
    <g>
      <path d="M414.7 128.1L267.2 70.5c-14.1-5.5-30 1.5-35.5 15.6l-117 299.6c-5.5 14.1 1.4 30 15.6 35.5l147.5 57.6c14.1 5.5 30-1.5 35.5-15.6l117-299.6c5.5-14.1-1.5-30-15.6-35.5zm-116.2-27.5l72.8 28.4c1.8.7 2.3 4 .9 7.4-1.3 3.4-3.9 5.6-5.7 4.9l-72.8-28.4c-1.9-.7-2.2-4.1-.9-7.5 1.3-3.4 3.8-5.5 5.7-4.8zm-84.6 324.1c-9-3.5-13.4-13.7-9.9-22.7s13.7-13.4 22.6-9.9c9 3.5 13.4 13.6 9.9 22.6s-13.7 13.5-22.6 10zm101.9-24.5l-160.3-62.6L244 111.1l160.3 62.6-88.5 226.5z" />
    </g>
  </svg>
);

export default TurningPhone;
