const getFromStorageObject = (storageKey, item) => { //eslint-disable-line
  const storageValue = localStorage.getItem(storageKey);
  if (storageValue) {
    const objectStorageValue = JSON.parse(storageValue);
    return objectStorageValue[item] ? objectStorageValue[item] : false;
  }
  return false;
};

export default { getFromStorageObject };
