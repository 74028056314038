import storage from 'utils/Storage';

let newState = {};
const understands = (state = {
  results: storage.getFromStorageObject('understands', 'results'),
  understandsDone: storage.getFromStorageObject('understands', 'understandsDone'),
}, action) => {
  switch (action.type) {
    case 'SET_RESULTS':
      newState = Object.assign({}, state, {
        ...state,
        results: {
          ...state.results,
          [action.payload.step]: action.payload.result,
        },
      });
      localStorage.setItem('understands', JSON.stringify(newState));
      return newState;
    case 'SET_FINISH_UNDERSTANDS':
      newState = Object.assign({}, state, {
        results: { ...state.results },
        understandsDone: action.payload.understandsDone,
      });
      localStorage.setItem('understands', JSON.stringify(newState));
      return newState;
    case 'CLEAR':
      return {
        results: {},
        understandsDone: false,
      };
    default:
      return state;
  }
};

export default understands;
