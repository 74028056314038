export default {
  main: '#37b5a7',
  white: '#ffffff',
  blackViolet: '#06081c',
  ebony: '#0C0E20',
  grey: '#696e86',
  whiteGrey: '#ececec',
  purple: '#771E77',
  darkGrey: '#404040',
};
