import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Block = styled.div`
  display: flex;
  flex-flow: wrap column;
  justify-content: center;
  align-items: center;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-flow: wrap row;
  justify-content: space-around;
  align-items: center;
  margin: 25px;
`;

const InfosText = styled.div`
  max-width: 500px;
  padding: 40px 0 0;
`;

const DeviceName = styled.h3`
  margin-bottom: 20px;
  font-weight: 600;
`;

const DeviceText = styled.p`
  font-weight: 300;
  opacity: 0.5;
`;


const getRows = infosRows => infosRows.map(item => (
  <Row key={item.name}>
    <item.icon />
    <InfosText>
      <DeviceName>{item.name}</DeviceName>
      <DeviceText>{item.text}</DeviceText>
    </InfosText>
  </Row>
));

const InfosBlock = ({ infosRows }) => (
  <Block>
    {getRows(infosRows)}
  </Block>
);

InfosBlock.propTypes = {
  infosRows: PropTypes.array.isRequired,
};

export default InfosBlock;
