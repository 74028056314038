const getNewStepTonal = (hear, nb) => {
  if (hear === 'left') {
    if (nb === 3) {
      return true;
    }
    return {
      hear: 'left',
      nb: nb === 3 ? 1 : nb + 1,
    };
  }
  return {
    hear: nb === 3 ? 'left' : 'right',
    nb: nb === 3 ? 1 : nb + 1,
  };
};

const getNewStepUnderstand = (nbSteps, nb) => {
  if (nb >= nbSteps) {
    return true;
  }
  return {
    nb: nb + 1,
  };
};

const getStepText = (nb) => {
  switch (nb) {
    case 1:
      return 'graves';
    case 2:
      return 'moyens';
    default:
      return 'aigus';
  }
};

const getHearText = (hear) => {
  switch (hear) {
    case 'left':
      return 'Gauche';
    default:
      return 'Droite';
  }
};

const getUnderstandText = (nb) => {
  switch (nb) {
    case 1:
      return 'Station de ski';
    case 2:
      return 'Rue';
    case 3:
      return 'Restaurant';
    default:
      return 'Gare ferroviaire';
  }
};

export default {
  getNewStepTonal,
  getNewStepUnderstand,
  getUnderstandText,
  getStepText,
  getHearText,
};
