import React from 'react';
import styled, { withTheme } from 'styled-components';
import PropTypes from 'prop-types';

const StyledInfosItem = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 27px 25px;
  margin: 5px;
  border-radius: 5px;
  background-color: ${props => (props.selected ? props.theme.main : props.theme.ebony)};
  cursor: pointer;
  transition: border-color .3s ease;
  transition: background-color .1s ease;

  &:hover {
    background-color: ${props => (props.selected ? props.theme.main : 'rgba(55, 181, 167, 0.8)')}
  }
`;

const InfosItem = ({ value, onClick, selected }) => (
  <StyledInfosItem onClick={onClick} selected={selected}>
    <span>{value}</span>
  </StyledInfosItem>
);

InfosItem.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

InfosItem.defaultProps = {
  selected: false,
};

export default withTheme(InfosItem);
