import Mac from 'components/Schemas/Mac';
import Windows from 'components/Schemas/Windows';

const InfosDesktop = [
  {
    name: 'Windows PC',
    text: `
      Cliquez sur l'icône de haut-parleur en bas à droite de votre écran pour contrôler le volume.
      Déplacer le curseur jusqu'à ce qu'il se trouve au milieu de la barre de volume.
    `,
    icon: Windows,
  }, {
    name: 'Apple Mac',
    text: `
      Utilisez les touches F11 et F12 de votre clavier pour régler le volume jusqu'à ce que la jauge de volume soit remplie à moitié.
    `,
    icon: Mac,
  },
];

export default InfosDesktop;
