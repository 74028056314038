import bar from 'audios/bar.m4a';
import bureau from 'audios/bureau.m4a';
import bus from 'audios/bus.m4a';
import gare from 'audios/gare.m4a';

const TestContent = [
  {
    question: 'Quel est le nom de la station de ski ?',
    file: bureau,
    desktop: 58.6,
    ios: 40.6,
    android: 41.6,
    responses:
      [
        {
          name: 'Chabamont',
          correct: false,
        },
        {
          name: 'Chabanan',
          correct: false,
        },
        {
          name: 'Chabanon',
          correct: true,
        },
        {
          name: 'Je ne sais pas',
          correct: false,
        },
      ],
  },
  {
    question: 'Quelle est la ligne de bus ?',
    file: bus,
    desktop: 58.6,
    ios: 40.6,
    android: 41.6,
    responses:
      [
        {
          name: 'La ligne V',
          correct: false,
        },
        {
          name: 'La ligne P',
          correct: false,
        },
        {
          name: 'La ligne B',
          correct: true,
        },
        {
          name: 'Je ne sais pas',
          correct: false,
        },
      ],
  },
  {
    question: 'Quel est le nom des pâtes ?',
    file: bar,
    desktop: 58.6,
    ios: 40.6,
    android: 41.6,
    responses:
      [
        {
          name: 'Lumaconi',
          correct: false,
        },
        {
          name: 'Manicotti',
          correct: false,
        },
        {
          name: 'Macaroni',
          correct: true,
        },
        {
          name: 'Je ne sais pas',
          correct: false,
        },
      ],
  },
  {
    question: "Quel est l'horaire du train ?",
    file: gare,
    desktop: 58.6,
    ios: 40.6,
    android: 41.6,
    responses:
      [
        {
          name: '16h15',
          correct: false,
        },
        {
          name: '13h15',
          correct: true,
        },
        {
          name: '7h15',
          correct: false,
        },
        {
          name: 'Je ne sais pas',
          correct: false,
        },
      ],
  },
];

export default TestContent;
