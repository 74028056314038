import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import VolumeIcon from 'components/Icons/VolumeIcon';
import Button from 'components/Button';
import SubTitle from 'components/Texts/SubTitle';
// import Wave from 'components/Wave';

import styled, { withTheme } from 'styled-components';
// import TestTonalBlock from './TestTonalBlock';
const Block = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 4em;
  text-align: center;
  color: ${props => props.theme.white};
`;

const StyledVolumeIcon = styled(VolumeIcon)`
  width: 20px;
  height: auto;
  fill: ${props => props.theme.main};
`;

const Paragraph = styled.p`
  padding: 50px 0;
`;

const TopHead = styled.p`
  text-transform: uppercase;
  font-size: 0.9rem;
  margin: 20px 0 10px;
`;

const TestTonalIntro = ({ loaded, onClick }) => (
  <React.Fragment>
    <Helmet>
      <title>Test tonal | Entendre</title>
    </Helmet>
    <Block>
      <StyledVolumeIcon />
      <TopHead>Test Tonal</TopHead>
      <SubTitle padding="35px 0 0">Une gêne est souvent liée à un trouble de la perception des sons faibles.</SubTitle>
      <Paragraph>
      Ce premier test évalue, pour différentes fréquences, l’intensité
      minimum que vous parvenez à percevoir.
      </Paragraph>
      <Button disabled={!loaded} color="green" onClick={e => onClick({ hear: 'right', nb: 1 }, e)}>Je commence le test</Button>
    </Block>
  </React.Fragment>
);

TestTonalIntro.propTypes = {
  onClick: PropTypes.func.isRequired,
  loaded: PropTypes.bool.isRequired,
};

export default withTheme(TestTonalIntro);
