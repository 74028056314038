import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Header from 'containers/partials/Header';
import Footer from 'containers/partials/Footer';
import media from 'utils/styledMixins';

const Container = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: center;
  min-width: calc(100vw - 15px);
  min-height: 100vh;
  position: relative;
  z-index: 1;
  background-image: ${props => `url(${props.backImgMobile})`};
  background-position: top;
  background-size: cover;

  ${media.tablet`
    background-image: ${props => `url(${props.backImg})`};
  `}
`;

const StyledMain = styled.main`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex: 1;
  justify-content: center;
  position: relative;
  z-index: 1;
  padding: 0 30px;
`;

const Main = ({
  children, backImg, backImgMobile, styles, noFooter, mentionsColor,
}) => (
  <React.Fragment>
    <Container backImg={backImg} backImgMobile={backImgMobile}>
      <Header />
      <StyledMain style={styles}>
        {children}
      </StyledMain>
      {!noFooter && <Footer mentionsColor={mentionsColor} />}
    </Container>
  </React.Fragment>
);

Main.propTypes = {
  children: PropTypes.node.isRequired,
  backImg: PropTypes.string.isRequired,
  backImgMobile: PropTypes.string.isRequired,
  styles: PropTypes.object,
  noFooter: PropTypes.bool,
  mentionsColor: PropTypes.string,
};

Main.defaultProps = {
  styles: {},
  noFooter: false,
  mentionsColor: '#ffffff',
};

export default Main;
