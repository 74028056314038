const steps = (state = {
  stepTonal: localStorage.getItem('stepTonal') ? JSON.parse(localStorage.getItem('stepTonal')) : {},
  stepUnderstand: localStorage.getItem('stepUnderstand') ? JSON.parse(localStorage.getItem('stepUnderstand')) : {},
}, action) => {
  switch (action.type) {
    case 'SET_STEP_TONAL':
      return Object.assign({}, state, {
        stepTonal: action.payload.stepTonal,
      });
    case 'SET_STEP_UNDERSTAND':
      return Object.assign({}, state, {
        stepUnderstand: action.payload.stepUnderstand,
      });
    case 'CLEAR':
      return {
        stepTonal: {},
        stepUnderstand: {},
      };
    default:
      return state;
  }
};

export default steps;
