import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import media from 'utils/styledMixins';


const StyledButton = styled.button`
    display: block;
    position: relative;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    outline: none;

    &::after {
      width: 68px;
      height: 68px;
      position: absolute;
      top: 50%;
      left: 50%;
      border-radius: 50%;
      content: "";
      transform: translate(-50%, -50%);
      border: ${props => (props.selected ? `2px solid ${props.theme.main}` : '1px solid rgba(255, 255, 255, 0.3);')};
      transition: border-color .3s ease;

      ${media.tablet`
        width: 150px;
        height: 150px;

        &:hover::after {
          border: 2px solid ${props => (props.selected ? props.theme.main : 'rgba(55, 181, 167, 0.8)')};
        }

        &:hover svg {
          fill: ${props => (props.selected ? props.theme.main : 'rgba(55, 181, 167, 0.8)')};
        }
      `}

    }

    svg {
      fill: ${props => (props.selected ? props.theme.main : 'rgba(255, 255, 255, 0.3);')};

    }
`;

const TutorialButton = ({
  children, onClick, selected,
}) => (
  <StyledButton selected={selected} onClick={onClick}>
    {children}
  </StyledButton>
);


TutorialButton.propTypes = {
  // color: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default withTheme(TutorialButton);
