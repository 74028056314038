import React from 'react';
import Lottie from 'react-lottie';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import animationData from './data.json';
import animationDataLight from './light.json';

const StyledWave = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform .1s ease;

  > div {
    ${props => (props.player
    ? 'height: auto !important;' : '')}
  }
`;

const Background = styled.div`
  width: 100vw;
  height: 100vh;

  background: ${props => `linear-gradient(to right, ${props.theme.ebony}  0%,#3c0531 100%)`};
`;

const LoadingMessage = styled.span`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${props => props.theme.white};
`;

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
};

const defaultOptionsLight = {
  loop: true,
  autoplay: true,
  animationData: animationDataLight,
};

const Wave = ({ player, light, level }) => (
  <TransitionGroup>
    <CSSTransition
      classNames="fade"
      timeout={1000}
    >
      <StyledWave player={player} style={{ zIndex: player ? 0 : 50, transform: player ? `scaleY(${(level + 25) / 100}) translateX(-50%)` : 'translateX(-50%)' }}>
        {!player
          ? (
            <Background>
              <Lottie
                width="100%"
                options={light ? defaultOptionsLight : defaultOptions}
              />
            </Background>
          )
          : (
            <Lottie
              width="100%"
              options={light ? defaultOptionsLight : defaultOptions}
            />
          )
        }
        {!player && <LoadingMessage>Chargement ...</LoadingMessage>}
      </StyledWave>
    </CSSTransition>
  </TransitionGroup>
);

Wave.propTypes = {
  player: PropTypes.bool,
  light: PropTypes.bool,
  level: PropTypes.number,
};

Wave.defaultProps = {
  player: false,
  light: false,
  level: 50,
};

export default withTheme(Wave);
