import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CloseIcon from 'components/Icons/Cross';
import media from 'utils/styledMixins';
// const SyledModal = styled(Modal)`

// `;

const StyledCloseIcon = styled(CloseIcon)`
  width: 25px;
  position: fixed;
  top: 32px;
  right: 32px;
  fill: #A1A1A1;
  cursor: pointer;
`;

Modal.setAppElement('#root');

const SimpleModal = ({
  children, closeModal, modalOpen, className, modalClassName, ...props
}) => (
  <Modal
    isOpen={modalOpen}
    // onAfterOpen={this.afterOpenModal}
    onRequestClose={closeModal}
    // style={customStyles}
    contentLabel="Example Modal"
    className={modalClassName}
    portalClassName={className}
    {...props}
  >
    {children}
    <StyledCloseIcon onClick={closeModal} />
  </Modal>
);

const StyledModal = styled(SimpleModal).attrs({
  overlayClassName: 'Overlay',
  modalClassName: 'Modal',
})`
  .Modal {
    top: 0;
    left: 0;
    position: fixed;
    width: 95vw;
    height: 95vh;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    overflow: scroll;
    border-radius: 2px;
    z-index: 6;
    background-color: #FFFFFF;

    ${media.tablet`
      width: 70%;
    `}
  }
  .Overlay {
    top: 0;
    left: 0;
    position: fixed;
    z-index: 5;
    width: 100vw;
    height: 100vh;
    background-color: rgba(11, 15, 33, .95);
  }

`;

SimpleModal.propTypes = {
  children: PropTypes.node.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  modalClassName: PropTypes.string.isRequired,
};

export default StyledModal;
