import tonals from './tonals';
import understands from './understands';
import tutoDone from './tutoDone';
import earDevice from './earDevice';
import step from './steps';

const entendreApp = {
  tonals,
  understands,
  step,
  tutoDone,
  earDevice,
};

export default entendreApp;
