import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { ConnectedRouter } from 'react-router-redux';

import { store, history } from 'actions/store';
import App from './app';

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <React.Fragment>
        <App />
      </React.Fragment>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
);
