import styled from 'styled-components';
import media from 'utils/styledMixins';

const SubTitle = styled.h2`
  font-weight: ${props => props.weight || 300};
  font-size: 1rem;
  line-height: 1.2;
  color: #ffffff;
  max-width:  90%;
  text-align: center;
  max-width:  ${props => props.maxWidth || '800px'};

  ${media.tablet`
    padding: ${props => props.padding || 0};
    font-size: 1.5rem;
  `}

  b {
    font-weight: 600;
  }
`;

export default SubTitle;
