import { DEFINE_EAR_DEVICE } from 'actions/earDevice';


const earDevice = (state = (localStorage.getItem('earDevice')), action) => {
  switch (action.type) {
    case DEFINE_EAR_DEVICE:
      return action.payload.earDevice;
    case 'CLEAR':
      return false;
    default:
      return state;
  }
};

export default earDevice;
