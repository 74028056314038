import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import PropTypes from 'prop-types';
import Main from 'containers/partials/Main';
import Button from 'components/Button';
import Information from 'components/Icons/Information';
import media from 'utils/styledMixins';
import entendreHome from 'images/entendreHome.jpg';
import entendreHomeMobile from 'images/entendreHomeMobile.jpg';
import styled from 'styled-components';
import Wave from 'components/Wave';
import PartnersList from 'components/Partners/PartnersList';
import Footer from 'containers/partials/Footer';

const StyledHomepage = styled.div`
  max-width: 700px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 5rem;
  text-align: center;
`;

const HomeMessage = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  font-weight: 300;
  font-size: 0.9rem;
  padding: 30px 20px;
  margin-bottom: 20px;

  ${media.desktop`
    padding: 50px;
  `}

  p {
    max-width: 475px;
    margin-left: 20px;
    color: ${props => props.theme.darkGrey}
  }
`;

const HomeTitle = styled.h2`
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.1;
  color: #41454c;

  span {
    color: #37b5a7;
  }
`;

const StyledInformation = styled(Information)`
  width: 35px;
  fill: ${props => props.theme.darkGrey};
`;

class HomepageContainer extends React.Component {
  state = {
    loader: false,
  }

  handleClick = () => {
    const { dispatch } = this.props;
    dispatch(push('/didacticiel/intro'));
  };

  render() {
    const { loader } = this.state;
    return (
      <React.Fragment>
        { loader && <Wave /> }
        <Main backImg={entendreHome} backImgMobile={entendreHomeMobile} noFooter style={{ overflow: loader ? 'hidden' : 'visible' }} mentionsColor="#000000">
          <StyledHomepage>
            <HomeTitle>
              Testez votre audition en moins de
              <span> 10 minutes</span>
            </HomeTitle>
            <HomeMessage>
              <StyledInformation />
              <p>
                Ceci est un test pré-évaluatoire non médical et ne remplace
                en aucun cas le diagnostic réalisé par votre médecin ORL.
              </p>
            </HomeMessage>
            <Button color="green" onClick={this.handleClick}>
              Je fais le test
            </Button>
          </StyledHomepage>
        </Main>
        <PartnersList />
        <Footer mentionsColor="#000000" />
      </React.Fragment>
    );
  }
}

HomepageContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const Homepage = connect(() => ({
  // location: state.router.location,
}))(HomepageContainer);

export default Homepage;
