import React from 'react';

const SpeakerInterfaceAudioSymbol = props => (
  <svg viewBox="0 0 486.5 486.5" {...props}>
    <path d="M251.6 68.9c-6.2 0-11.6 2.3-16.1 6.8l-119 76.2H67c-6.2 0-11.6 2.3-16.1 6.8-4.5 4.5-6.8 9.9-6.8 16.1V312c0 6.2 2.3 11.6 6.8 16.1 4.5 4.5 9.9 6.8 16.1 6.8h49.5l119 76.2c4.5 4.5 9.9 6.8 16.1 6.8 6.2 0 11.5-2.3 16.1-6.8 4.5-4.5 6.8-9.9 6.8-16.1V91.7c0-6.2-2.3-11.6-6.8-16.1-4.6-4.5-9.9-6.7-16.1-6.7zM379.9 293.8c10.1-15.6 15.2-32.5 15.2-50.6s-5.1-35-15.2-50.7c-10.1-15.7-23.5-26.8-40.2-33.2-2.4-1.2-5.4-1.8-8.9-1.8-6.2 0-11.6 2.2-16.1 6.6-4.5 4.4-6.8 9.8-6.8 16.3 0 5 1.4 9.2 4.3 12.7s6.3 6.4 10.4 8.9c4 2.5 8.1 5.2 12.1 8.2 4 3 7.5 7.2 10.4 12.7 2.9 5.5 4.3 12.3 4.3 20.4 0 8.1-1.4 14.9-4.3 20.4-2.9 5.5-6.3 9.7-10.4 12.7-4.1 3-8.1 5.7-12.1 8.2-4.1 2.5-7.5 5.5-10.4 8.9-2.9 3.5-4.3 7.7-4.3 12.7 0 6.4 2.3 11.9 6.8 16.3s9.9 6.6 16.1 6.6c3.6 0 6.5-.6 8.9-1.8 16.7-6.7 30.1-17.9 40.2-33.5z" />
    <path d="M375.8 75c-3.1-1.2-6.2-1.8-9.3-1.8-6.2 0-11.6 2.3-16.1 6.8-4.5 4.5-6.8 9.9-6.8 16.1 0 9.3 4.6 16.3 13.9 21.1 13.3 6.9 22.4 12.1 27.2 15.7 17.6 12.9 31.4 29 41.3 48.4 9.9 19.4 14.8 40.1 14.8 62s-4.9 42.6-14.8 62-23.6 35.6-41.3 48.4c-4.8 3.6-13.8 8.8-27.2 15.7-9.3 4.8-13.9 11.8-13.9 21.1 0 6.2 2.3 11.6 6.8 16.1 4.5 4.5 10 6.8 16.4 6.8 2.9 0 5.8-.6 8.9-1.8 33.3-14.1 60.1-36.5 80.4-67.4 20.2-30.9 30.4-64.5 30.4-100.9 0-36.4-10.1-70.1-30.4-100.9-20.2-30.9-47-53.4-80.3-67.4z" />
  </svg>
);

export default SpeakerInterfaceAudioSymbol;
