import { FINISH_TUTO } from 'actions/tuto';


const tutoDone = (state = (localStorage.getItem('tutoDone') === 'true'), action) => {
  switch (action.type) {
    case FINISH_TUTO:
      return action.payload.tutoDone;
    case 'CLEAR':
      return false;
    default:
      return state;
  }
};

export default tutoDone;
