import Android from 'components/Schemas/AndroidTab';
import Ios from 'components/Schemas/IosTab';

const InfosTablet = [
  {
    name: 'Android tablette',
    text: `
      Utilisez les boutons sur le côté de votre appareil pour régler le volume jusqu'à ce que le curseur se trouve au milieu de la barre de volume.
    `,
    icon: Android,
  }, {
    name: 'Apple iPad',
    text: `
      Utilisez les boutons sur le côté droit de votre iPad pour régler le volume jusqu'à ce que la jauge de volume soit remplie à moitié.
    `,
    icon: Ios,
  },
];

export default InfosTablet;
