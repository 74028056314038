import React from 'react';
import styled, { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import VolumeController from 'components/VolumeController'; //eslint-disable-line
import SubTitle from 'components/Texts/SubTitle'; //eslint-disable-line
import Wave from 'components/Wave';
import Steps from 'components/Steps';
import steps from 'utils/Steps';
import { connect } from 'react-redux';
import Transition from 'react-transition-group/Transition';
import media from 'utils/styledMixins';

const duration = 300;

const defaultStyle = {
  height: '100%',
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
};

const Block = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  position: relative;
`;

const BlockTop = styled.div`
  width: 100%;
  display: flex;
  flex-flow: wrap column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 25px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 0;

`;

const Hear = styled.p`
  text-align: center;
  text-transform: uppercase;
  font-size: 0.9rem;
  margin: 0;

  ${media.tablet`
    margin: 20px 0 10px;
  `}
`;


class TestTonalBlock extends React.Component {
  state = {
    isChanging: true,
  }

  componentDidUpdate(nextProps) {
    const { locationKey } = this.props;
    if (locationKey !== nextProps.locationKey) {
      this.setState({ // eslint-disable-line
        // TODO: change this
        isChanging: false,
      });
      setTimeout(() => {
        this.setState({
          isChanging: true,
        });
      }, 300);
    }
  }

  // loader,
  render() {
    const {
      children,
      volumeController,
      changeVolume,
      level,
      hear,
      nb,
    } = this.props;

    const { isChanging } = this.state;
    return (
      <Block>
        <BlockTop>
          <Hear>
            Oreille &nbsp;
            {steps.getHearText(hear)}
          </Hear>
          <Steps nbSteps={3} nb={nb} />
          <SubTitle>
            {'Déplacez les flèches pour ajuster le volume jusqu\'à entendre très faiblement '}
            <b>
              {`les sons ${steps.getStepText(nb)}`}
            </b>
          </SubTitle>
        </BlockTop>
        <Transition in={isChanging} appear timeout={duration}>
          {state => (
            <div style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
            >
              <Wave player autoplay level={level} />
              {volumeController && <VolumeController level={level} changeVolume={changeVolume} />}
            </div>
          )}
        </Transition>
        {children}
        {/* {loader ? <Wave /> : <div />} */}
      </Block>
    );
  }
}

TestTonalBlock.propTypes = {
  children: PropTypes.node.isRequired,
  changeVolume: PropTypes.func.isRequired,
  volumeController: PropTypes.bool,
  level: PropTypes.number.isRequired,
  hear: PropTypes.string.isRequired,
  nb: PropTypes.number.isRequired,
  locationKey: PropTypes.string.isRequired,
  // loader: PropTypes.bool.isRequired,
};

TestTonalBlock.defaultProps = {
  volumeController: false,
};

export default withTheme(connect(state => ({
  locationKey: state.router.location.key,
}))(TestTonalBlock));
