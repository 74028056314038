import bernafon from 'images/partners/bernafon.jpg';
import cochlear from 'images/partners/cochlear.jpg';
import hansaton from 'images/partners/hansaton.jpg';
import oticon from 'images/partners/oticon.jpg';
import phonak from 'images/partners/phonak.jpg';
import resound from 'images/partners/resound.jpg';
import sennheiser from 'images/partners/sennheiser.jpg';
import seimensSignal from 'images/partners/siemens-signa.jpg';
import starkey from 'images/partners/starkey.jpg';
import unitron from 'images/partners/unitron.jpg';
import widex from 'images/partners/widex.jpg';

const partners = [
  {
    name: 'bernafon',
    img: bernafon,
    href: 'https://fr.bernafon.com/',
  },
  {
    name: 'cochlear',
    img: cochlear,
    href: 'https://www.cochlear.com/fr/home',
  },
  {
    name: 'hansaton',
    img: hansaton,
    href: 'https://www.hansaton.de/fr/',
  },
  {
    name: 'oticon',
    img: oticon,
    href: 'https://www.oticon.fr/hearing-aid-users',
  },
  {
    name: 'phonak',
    img: phonak,
    href: 'https://www.phonak.com/fr/fr.html',
  },
  {
    name: 'resound',
    img: resound,
    href: 'https://www.resound.com/fr-fr',
  },
  {
    name: 'sennheiser',
    img: sennheiser,
    href: 'https://fr-fr.sennheiser.com/',
  },
  {
    name: 'siemens-signa',
    img: seimensSignal,
    href: 'https://www.signia-audition.fr/',
  },
  {
    name: 'starkey',
    img: starkey,
    href: 'https://www.starkey.fr/',
  },
  {
    name: 'unitron',
    img: unitron,
    href: 'http://unitron.com/content/unitron/fr/fr/consumer.html',
  },
  {
    name: 'widex',
    img: widex,
    href: 'https://www.widex.fr/',
  },
];

export default partners;
