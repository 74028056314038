import React from 'react';
import { Helmet } from 'react-helmet';
import styled, { withTheme } from 'styled-components';
import axios from 'axios';
import SubTitle from 'components/Texts/SubTitle';
import Main from 'containers/partials/Main';
import media from 'utils/styledMixins';
import Footer from 'containers/partials/Footer';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TestContent from 'containers/TestUnderstand/TestContent';
import PartnersList from 'components/Partners/PartnersList';

// import entendreResults from 'images/entendreResults.jpg';
import dialogBackground from 'images/dialogBackground.png';
import ouietest from 'images/ouitest.jpg';
// import ServiceIcon from 'components/Icons/Desktop';
// import OuiTestLogo from 'components/Icons/OuiTest';

// const StyledOuiTestLogo = styled(OuiTestLogo)`
//   width: 150px;
//   margin-top: 50px;
// `;

// const StyledServiceIcon = styled(ServiceIcon)`
//   width: 100%;
//   height: 100%;
//   fill: #ffffff;
// `;

const StyledResults = styled.div`
  width: 100vw;
  text-align: center;
`;

const ResultBlock = styled.div`
  padding: 0 15px 50px;
  display: flex;
  flex-flow: wrap column;
  justify-content: flex-start;
  align-items: center;
  background-color: #f3f3f3;

  ${media.tablet`
    padding: 0 50px 50px;
  `}
`;

// const RealTest = styled.div`
//   min-height: 50%;
//   display: flex;
//   justify-content: flex-start;
//   align-items: center;
//   flex-direction: column;
//   color: ${props => props.theme.white};
//   background-image: url(${props => props.backImg});
//   background-size: cover;
// `;

const StyledTop = styled.p`
  padding: 20px 0 0;
  text-align: center;
  text-transform: uppercase;

  ${media.tablet`
    padding: 50px 0 0;
  `}
`;

const StyledSubTitle = SubTitle.extend`
  padding: 10px 0;
  margin: 20px;
  font-size: 1.5rem;
  font-weight: 600;
  color: ${props => props.color};

  ${media.tablet`
    padding: 25px 0;
    margin: 50px;
  `}
`;

// const Line = styled.span`
//   width: 100vw;
//   height: 1px;
//   background-color: #ebebeb;
//   margin: 50px 0;
// `;

const ShareContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin-top: 50px;
`;

const Share = styled.div`
  width: 100%;
  height: ${props => (props.number === '1' ? '100%' : 'auto')};
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;

  ${media.tablet`
    width: calc(50% - 15px);
    height: auto;
  `}

  ${media.giant`
    justify-content: space-around;
  `}
`;

const ShareSubTitle = styled.p`
  color: #424242;
  font-weight: 300;
  text-align: left;
  padding-bottom: 25px;
`;

const ShareSubTitleCenter = styled.p`
  color: ${props => (props.theme.white)};
  font-weight: 300;
  text-align: left;
  font-size: 14px;
  padding-right: 35px;
  padding-bottom: 25px;

  ${media.tablet`
    font-size: 28px;
  `}

  ${media.giant`
    padding-right: 105px;
  `}
`;

const ShareIntroCenter = styled.p`
  color: ${props => (props.theme.white)};
  font-weight: 300;
  text-align: left;
  padding-bottom: 25px;
`;


const ShareBox = styled.div`
  width: 100%;
  background-color: ${props => (props.number === '1' ? props.theme.main : '#FFFFFF')};
  background-image: ${props => (props.number === '1' ? `url(${dialogBackground})` : '')};
  background-position: bottom right;
  background-repeat: no-repeat;
  border-radius: 2px;
  /* height: 175px; */
  height: ${props => (props.number === '1' ? '100%' : 'auto')};
  display: flex;
  padding: ${props => (props.number === '1' ? '30px 45px' : '40px')};
  justify-content: center;
  align-items: ${props => (props.number === '1' ? 'flex-start' : 'center')};
  flex-direction: column;
  margin-bottom: ${props => (props.hasMarginBottom === '1' ? '15px' : '0')};
  margin-top: ${props => (props.number === '1' ? '15px' : 0)};
  position: relative;
  box-shadow: -4px 4px 5px 0px rgba(232,232,233,1);
  box-sizing: border-box;

  ${media.tablet`
    padding: ${props => (props.number === '1' ? '0 45px' : '40px')};
    margin-top: 0;
  `}

   /* ${media.mega`
    width: auto;
  `} */

`;

const EmailBox = styled.form`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:  center;
`;

const EmailField = styled.input`
  width: 100%;
  position: relative;
  z-index: 2;
  display: inline-block;
  box-sizing: border-box;
  padding: 20px 45px 20px 20px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
  transition: transform .2s ease;
  background-color: 'transparent';
  border: ${props => (props.errorEmail ? '1px solid #C62828' : '1px solid #e3e3e3')};
  color: #424242;

  &::placeholder {
    color: #afafaf;
  }

  &:focus {
    /* border: ${props => (!props.errorEmail ? `1px solid ${props.theme.main}` : '1px solid #C62828')};
    outline: 0; */
  }
`;

const SendMail = styled.input`
  position: absolute;
  top: 50%;
  right: 1px;
  padding: 17px 20px;
  background-color: #fafafa;
  border: 0;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 600;
  color: #37b5a7;
  cursor: pointer;
  transform: translateY(-50%);
  z-index: 2;
`;


const SocialList = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const SocialItem = styled.li`
  background-color: ${props => props.color};
  color: #ffffff;
  padding: 19px 29px;
  margin-right: 10px;
  border-radius: 50px;
  cursor: pointer;
`;

const ParagraphBlock = styled.div`
  max-width: 1200px;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;

  ${media.tablet`
    flex-flow: row;
  `}
`;

const Paragraph = styled.p`
  text-align: left;
  /* color: ${props => props.theme.darkGrey}; */

  ${media.tablet`
    ${props => (props.position === 'left' ? 'margin-right: 25px;' : 'margin-left: 25px')}
  `}
`;

// const Box = styled.div`
//   display: flex;
//   flex-flow: column wrap;
//   justify-content: flex-start;
//   align-items: center;
//   text-align: left;
//   box-sizing: border-box;
//   border-radius: 5px;
//   padding: 40px;
//   margin: 10px;
//   color: ${props => props.theme.white};
//   background-color: ${props => props.theme[props.color]};

//   p {
//     padding: 20px 0;
//   }

//   .text {
//     width: 100%;
//     max-width: 90%;
//   }

//   ${media.tablet`
//     height: auto;
//     width: calc(50% - 20px);

//   `}

//   ${media.desktop`
//     flex-flow: row nowrap;
//   `}
// `;

// const OffersBlock = styled.div`
//   display: flex;
//   flex-flow: row wrap;

//    ${media.tablet`
//   `}
// `;

// const Container = styled.div`
//   max-width: 1200px;
//   padding-bottom: 3rem;
// `;

// const CenterBlock = styled.div`
//   text-align: center;
//   display: flex;
//   flex-flow: column wrap;
//   justify-content: center;
//   align-items: center;
// `;

const LinkButton = styled.a`
  position: relative;
  z-index: 2;
  display: inline-block;
  padding: 20px 35px;
  margin: ${props => props.margin};
  border-radius: 50px;
  font-size: 14px;
  font-weight: 300;
  cursor: pointer;
  text-decoration: none;
  transform: scale(1);
  transition: transform .2s ease;
  background-color: ${props => (props.transparent ? 'transparent' : props.theme.white)};
  border: ${props => (props.transparent ? '1px solid rgba(255, 255, 255, 0.2)' : '0')};
  color: ${props => (props.theme.main)};

  &:hover {
    transform: scale(1.1);
  }
`;

// const IconBlock = styled.div`
//   width: 30%;
//   padding: 0 0 40px;

//   ${media.tablet`
//     padding: 60px 60px 60px 20px;
//   `}
// `;

const SocialBox = styled.div`
  text-align: left;
`;

// const Sup = styled.sup`
//   font-size: 12px;
//   top: -8px;
//   left: 5px;
//   position: relative;
// `;

const ResponseMessage = styled.p`
  width: 91%;
  height: 89%;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  color: ${props => (props.error ? '#C62828' : props.theme.main)};
  background-color: ${props => props.theme.white};
  padding-top: 10px;
  z-index: 5;
`;

const OuieTest = styled.div`
  display: flex;
  justify-content: center;
  padding: 40px;
  background-image: url(${ouietest});
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
`;

const OuieTestContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  width: 100%;
  max-width: 1200px;
  padding: 200px 0;
`;

const OuieTestTitle = styled.h2`
  margin-bottom: 60px;
  color: ${props => (props.theme.white)};
  font-size: 3rem;
  text-align: left;
`;

const OuieTestDesc = styled.p`
  margin-bottom: 40px;
  color: ${props => (props.theme.white)};
  font-size: 1.4rem;
`;

const ValidateEmail = (mail) => {
  if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
    return (true);
  }
  return (false);
};

const shareAction = (to) => {
  if (navigator.share) {
    navigator.share({
      title: 'Entendre test auditif',
      text: 'Testez votre audition gratuitement en moins de 10 minutes avec Entendre',
      url: window.location.origin,
    })
      .then(() => {
        console.log('Thanks for sharing!');
      })
      .catch(err => console.log('Couldn\'t share because of', err.message));
  } else {
    console.log('web share not supported');
    if (to === 'fb') {
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURI(window.location.origin)}`);
    } else {
      window.open(`https://twitter.com/intent/tweet/?url=${encodeURI(window.location.origin)}&text=Testez%20votre%20audition%20gratuitement%20en%20moins%20de%2010%20minutes%20avec%20Entendre`);
    }
  }
};

const getBadDB = (object) => {
  let goodCounter = 0;
  const currentLength = Object.keys(object).length;
  for (let i = 1; i <= currentLength; i += 1) {
    const db = object[i];
    if (db <= 20) {
      goodCounter += 1;
    }
  }
  return { goodCounter, currentLength };
};

const getBadAnwser = (results) => {
  const object = TestContent;
  let goodCounter = 0;
  const currentLength = Object.keys(object).length;
  for (let i = 0; i < currentLength; i += 1) {
    const isGood = object[i].responses[results[i + 1]].correct;
    if (isGood) {
      goodCounter += 1;
    }
  }
  return { goodCounter, currentLength };
};

class Results extends React.Component {
  state = {
    userEmail: '',
    response: false,
    error: false,
    errorEmail: false,
    audition: 'good',
  }

  componentDidMount() {
    this.setState({
      audition: this.getResult(),

    });
  }

  getText() {
    const { audition } = this.state;
    switch (audition) {
      case 'bad':
        return {
          title: 'Audition anormale',
          color: '#f35940',
          paragraph: 'Votre audition nécessite un examen approfondi chez votre ORL ou votre audioprothésiste.',
        };
      case 'medium':
        return {
          title: 'Audition correcte',
          color: '#f3b240',
          paragraph: 'Votre audition semble correcte malgré quelques signes de baisse, pensez à la faire tester une fois par an par un professionnel et à bien vous protéger des bruits forts.',
        };
      default:
        return {
          title: 'Audition excellente',
          color: '#37b5a7',
          paragraph: 'Votre audition semble excellente, pensez à la préserver en vous protégeant des bruits forts.',
        };
    }
  }

  getResult() {
    let currentState = 'good';
    const { tonals, understands } = this.props;
    const left = getBadDB(tonals.results.left);
    const right = getBadDB(tonals.results.right);
    const understandResults = getBadAnwser(understands.results);

    if (left.goodCounter < left.currentLength - 1) {
      return 'bad';
    }

    if (left.goodCounter === left.currentLength - 1) {
      currentState = 'medium';
    }

    if (right.goodCounter < right.currentLength - 1) {
      return 'bad';
    }

    if (right.goodCounter === right.currentLength - 1) {
      currentState = 'medium';
    }

    if (understandResults.goodCounter < understandResults.currentLength - 1) {
      return 'bad';
    }

    if (understandResults.goodCounter === understandResults.currentLength - 1) {
      currentState = 'medium';
    }
    return currentState;
  }

  getResponseMessage() {
    const { error, userEmail } = this.state;
    if (error) {
      return <ResponseMessage error>Erreur, veuillez réessayer ultérieurement</ResponseMessage>;
    }
    return <ResponseMessage>{`Votre bilan a bien été envoyé à ${userEmail}`}</ResponseMessage>;
  }

  handleChange(event) {
    this.setState({
      userEmail: event.target.value,
      response: false,
      error: false,
    });
  }

  handleSendEmail(e) {
    const { userEmail } = this.state;
    const { audition } = this.state;
    let templateId = 6;
    switch (audition) {
      case 'good':
        templateId = 4;
        break;
      case 'medium':
        templateId = 6;
        break;
      case 'bad':
        templateId = 7;
        break;
      default:
        templateId = 6;
        break;
    }
    e.preventDefault();
    if (ValidateEmail(userEmail)) {
      axios({
        method: 'post',
        url: 'https://sendinblue.novembre.com/sendMail',
        data: {
          from: 'Entendre',
          params: JSON.stringify({ to: [{ email: userEmail }], sender: { email: 'no-reply@entendre.com' }, templateId }),
          url: 'https://api.sendinblue.com/v3/smtp/email',
        },
      })
        .then(() => this.setState({ response: true }))
        .catch(() => this.setState({ response: true, error: true }));
    } else {
      this.setState({ errorEmail: true });
    }
  }

  render() {
    const { errorEmail, response, userEmail } = this.state;
    const text = this.getText();
    return (
      <React.Fragment>
        <Helmet>
          <title>Résultat de votre test auditif | Entendre</title>
        </Helmet>
        <Main backImg="" backImgMobile="" noFooter styles={{ padding: 0 }}>
          <StyledResults>
            <ResultBlock>
              <StyledTop>Votre résultat</StyledTop>
              <StyledSubTitle color={text.color}>{text.title}</StyledSubTitle>
              <ParagraphBlock>
                <Paragraph position="left">
                  {text.paragraph}
                </Paragraph>
              </ParagraphBlock>
              {/* <Line /> */}
              <ShareContainer>
                <Share>
                  <ShareBox hasMarginBottom="1">
                    <ShareSubTitle>Recevoir mon résultat par mail</ShareSubTitle>
                    <EmailBox onSubmit={e => this.handleSendEmail(e)}>
                      <EmailField errorEmail={errorEmail} onChange={e => this.handleChange(e)} value={userEmail} placeholder="Adresse e-mail" />
                      <SendMail type="submit" value="ok" />
                    </EmailBox>
                    {response && this.getResponseMessage()}
                  </ShareBox>
                  <ShareBox>
                    <SocialBox>
                      <ShareSubTitle>Inviter mes proches à passer le test : </ShareSubTitle>
                      <SocialList>
                        <SocialItem onClick={e => shareAction('fb', e)} rel="noopener" color="#415498">
                          Facebook
                        </SocialItem>
                        <SocialItem onClick={e => shareAction('twitter', e)} color="#3EA4D1">
                          Twitter
                        </SocialItem>
                      </SocialList>
                    </SocialBox>
                  </ShareBox>
                </Share>
                <Share number="1">
                  <ShareBox number="1">
                    <ShareSubTitleCenter>
                      Trouver le centre le plus proche de chez vous :
                    </ShareSubTitleCenter>
                    <ShareIntroCenter>
                      Coordonnées, demande de rendez-vous…, trouvez votre centre auditif
                    </ShareIntroCenter>
                    <LinkButton href="https://www.entendre.com/trouver_centre?utm_source=test_audio&utm_medium=page_resultat">Trouver mon centre</LinkButton>
                  </ShareBox>
                </Share>
              </ShareContainer>
            </ResultBlock>
            <OuieTest>
              <OuieTestContainer>
                <OuieTestTitle>
                  Un bilan auditif
                  <br />
                  & essai gratuits
                </OuieTestTitle>
                <OuieTestDesc>
                  Bien entendu, sans engagement !
                </OuieTestDesc>
                <LinkButton href="https://landing.entendre.com?utm_source=test_audio&utm_medium=page_resultat">Découvrir Ouïe test</LinkButton>
              </OuieTestContainer>
            </OuieTest>
            <PartnersList />
            <Footer mentionsColor={props => (props.theme.darkGrey)} />
          </StyledResults>
        </Main>
      </React.Fragment>
    );
  }
}

Results.propTypes = {
  tonals: PropTypes.object.isRequired,
  understands: PropTypes.object.isRequired,
};

export default withTheme(connect(state => ({
  tonals: state.tonals,
  understands: state.understands,
}))(Results));
