import React from 'react';
import SimpleModal from 'components/Modals/SimpleModal';
import Mentions from 'texts/mentions.js'; //eslint-disable-line
import styled from 'styled-components';
import propTypes from 'prop-types';

// const OpenButton = styled.button`
//   background-color: transparent;
//   border: 0;
//   padding-top: 0;
//   padding-bottom: 20px;
//   color: ${props => props.color};
//   /* font-size: 0.8rem; */
//   font-size: 12px;
//   text-transform: uppercase;
//   opacity: 0.5;
//   cursor: pointer;
// `;

const StyledFooter = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;
  padding: 20px 0 15px;
`;

const FooterLink = styled.a`
  margin-bottom: 5px;
  margin-left: 5px;
  padding: 0 7px;
  color: ${props => props.color};
  font-size: 12px;
  text-decoration: none;
  text-transform: uppercase;
  opacity: .5;
`;
class Footer extends React.Component {
  state = {
    modalOpen: false,
    content: '',
  }

  openModal(content) {
    this.setState({
      modalOpen: true,
      content,
    });
  }

  closeModal() {
    this.setState({
      modalOpen: false,
    });
  }

  render() {
    const { modalOpen, content } = this.state;
    const { mentionsColor } = this.props;
    return (
      <StyledFooter>
        {/* <OpenButton
          onClick={e => this.openModal(<Mentions />, e)}
          color={mentionsColor}
        >
          Mentions légales
        </OpenButton> */}
        <FooterLink color={mentionsColor} href="https://www.entendre.com/mentions_legales" target="_blank" rel="noopener noreferrer">Mentions légales</FooterLink>
        <FooterLink color={mentionsColor} href="https://www.entendre.com/donnees_personnelles" target="_blank" rel="noopener noreferrer">Données personnelles</FooterLink>
        {/* <FooterLink color={mentionsColor} href="#tarteaucitron">
          Gestion des cookies
        </FooterLink> */}
        <FooterLink color={mentionsColor} href="https://www.facebook.com/lesaudioprothesistesentendre/" target="_blank" rel="noopener noreferrer">Suivez-nous sur facebook</FooterLink>
        <SimpleModal modalOpen={modalOpen} closeModal={e => this.closeModal(e)}>
          {content}
        </SimpleModal>
      </StyledFooter>
    );
  }
}

Footer.propTypes = {
  mentionsColor: propTypes.string,
};

Footer.defaultProps = {
  mentionsColor: '#FFFFFF',
};


export default Footer;
