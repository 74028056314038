import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import DesktopIcon from 'components/Icons/Desktop';
import TabletIcon from 'components/Icons/Tablet';
import MobileIcon from 'components/Icons/Mobile';
import styled, { withTheme } from 'styled-components';
import media from 'utils/styledMixins';
import SubTitle from 'components/Texts/SubTitle';

import InfosList from 'components/Infos/InfosList';
import InfosItem from 'components/Infos/InfosItem';
import InfosBlock from 'components/Infos/InfosBlock';

import TutorialButton from 'components/TutorialButton';

import infosDesktop from './InfosDevices/InfosDesktop';
import infosMobile from './InfosDevices/InfosMobile';
import infosTablet from './InfosDevices/InfosTablet';


const StyledDesktopIcon = styled(DesktopIcon)`
  width: 1.5rem;
  height: 100%;
  padding: 0 20px;
  color: ${props => props.theme.white};
  fill: ${props => props.theme.white};
`;

const StyledTabletIcon = styled(TabletIcon)`
  width: 1.5rem;
  height: 100%;
  padding: 0 20px;
  color: ${props => props.theme.white};
  fill: ${props => props.theme.white};
  transform: rotate(-90deg);
`;

const StyledMobileIcon = styled(MobileIcon)`
  width: 1.5rem;
  height: 100%;
  padding: 0 20px;
  color: ${props => props.theme.white};
  fill: ${props => props.theme.white};
`;

const TutorialBlock = styled.div`
  width: 100%;
  box-sizing: border-box;
  background-color: ${props => props.theme.blackViolet};
  border-radius: 5px;
  margin-bottom: 33px;
  padding: 40px 24px;

  ${media.desktop`
    padding: 32px 84px;
  `}
`;

class TutorialDevice extends React.Component {
  state = {
    device: -1,
  }

  handleClick = (device) => {
    this.setState({
      device,
    });
  }

  render() {
    const { device } = this.state;
    const { onClick } = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>Choisissez votre appareil et réglez le volume sur 50% | Entendre</title>
        </Helmet>
        <SubTitle maxWidth="700px">Choisissez votre appareil et réglez votre volume sur 50%</SubTitle>
        <InfosList>
          <InfosItem>
            <TutorialButton selected={device === 0} onClick={e => this.handleClick(0, e)}>
              <StyledDesktopIcon className="tutorial__icon" />
            </TutorialButton>
          </InfosItem>
          <InfosItem className="tutorial__infositem">
            <TutorialButton selected={device === 1} onClick={e => this.handleClick(1, e)}>
              <StyledTabletIcon className="tutorial__icon tutorial__icon_rotate" />
            </TutorialButton>
          </InfosItem>
          <InfosItem className="tutorial__infositem">
            <TutorialButton selected={device === 2} onClick={e => this.handleClick(2, e)}>
              <StyledMobileIcon className="tutorial__icon" />
            </TutorialButton>
          </InfosItem>
        </InfosList>
        <TutorialBlock>
          {device === -1 && <p>Merci de sélectionner un appareil</p>}
          {device === 0 && <InfosBlock infosRows={infosDesktop} />}
          {device === 1 && <InfosBlock infosRows={infosTablet} />}
          {device === 2 && <InfosBlock infosRows={infosMobile} />}
        </TutorialBlock>
        <Button color="green" disabled={device === -1} onClick={e => onClick('audio', e)}>Mon appareil est prêt</Button>
      </React.Fragment>
    );
  }
}

TutorialDevice.propTypes = {
  onClick: PropTypes.func.isRequired,
};


export default withTheme(TutorialDevice);
