import React from 'react';
import { Route, withRouter, Switch } from 'react-router';
import { Helmet } from 'react-helmet';
import { replace, push } from 'react-router-redux';
import { connect } from 'react-redux';
import WebFont from 'webfontloader';
import styled, { ThemeProvider } from 'styled-components';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import * as Sentry from '@sentry/browser';
import PropTypes from 'prop-types';
import Homepage from 'containers/Homepage';
import Tutorial from 'containers/Tutorial';
import TestTonal from 'containers/TestTonal';
import TestUnderstand from 'containers/TestUnderstand';
import Results from 'containers/Results';
import PrivateRoute from 'components/PrivateRoute';
import RestoreModal from 'components/Modals/RestoreModal';
import routes from 'utils/Routes';
import favicon from 'images/favicon.ico';
import { emptyAllAction } from 'actions/empty';
import OrientationModal from 'components/Modals/OrientationModal';
// import DevBar from 'components/DevBar';
import theme from './theme';

import baseStyles from './base-styles';

Sentry.init({
  dsn: 'https://6393a34726424063916529e69b61da88@sentry.io/1301954',
  // integrations: [new MyAwesomeIntegration()]
});

WebFont.load({
  google: {
    families: ['Ubuntu'],
  },
});

const TransitionBlock = styled.div`

`;

class App extends React.Component {
  state = {
    // restoreModalOpen: true, // TODO: change this
    restoreModalOpen: false,
    hasError: false,
  }

  componentDidMount() {
    const {
      tutoDone,
      tonalsDone,
      understandsDone,
      step,
      dispatch,
    } = this.props;
    const initialRoute = routes.getInitialRoute(tutoDone, tonalsDone, understandsDone, step);
    const actualSession = !!sessionStorage.getItem('actualSession');
    if (!actualSession) {
      sessionStorage.setItem('actualSession', true);
    } else {
      this.openRestoreModal();
    }
    if (initialRoute) {
      dispatch(replace(initialRoute));
    }
  }

  // shouldComponentUpdate(nextProps) {
  //   const {
  //     tutoDone,
  //     tonalsDone,
  //     understandsDone,
  //     step,
  //   } = this.props;
  //   const initialRoute = routes.getInitialRoute(tutoDone, tonalsDone, understandsDone, step);
  //   if (nextProps.location.pathname !== initialRoute) {
  //     return false;
  //   }
  //   return true;
  // }

  componentDidCatch(error, errorInfo) {
    // Display fallback UI
    this.setState({ hasError: true });
    Sentry.configureScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
    });
    Sentry.captureException(error);
  }

  openRestoreModal() {
    this.setState({
      restoreModalOpen: true,
    });
  }

  closeRestoreModal() {
    this.setState({
      restoreModalOpen: false,
    });
  }

  emptyAll() {
    const { dispatch } = this.props;
    dispatch(emptyAllAction());
    dispatch(push('/'));
    this.closeRestoreModal();
  }

  render() {
    baseStyles();
    const { restoreModalOpen, hasError } = this.state;
    if (hasError) {
      // render fallback UI
      Sentry.showReportDialog();
      return (
        <h1 style={{
          position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
        }}
        >
          {'Une erreur est survenue, l\'erreur à bien été envoyé, merci de recharger votre page'}
        </h1> //eslint-disable-line
      );
    }
    return (
      <React.Fragment>
        <Helmet>
          <title>Test auditif en 10 minutes | Entendre</title>
          <link rel="icon" href={favicon} />
        </Helmet>
        <OrientationModal />
        <Route
          render={({ location }) => (
            <ThemeProvider theme={theme}>
              <TransitionBlock>
                {/* <DevBar /> */}
                { restoreModalOpen
                    && (
                    <RestoreModal
                      emptyAll={e => this.emptyAll(e)}
                      closeModal={e => this.closeRestoreModal(e)}
                    />
                    )
                  }
                <TransitionGroup
                  childFactory={child => React.cloneElement(
                    child,
                    { classNames: 'slide', timeout: 1000 },
                  )}
                >
                  <CSSTransition
                    key={location.pathname.split('/')[1] || '/'}
                    classNames="slide"
                    timeout={600}
                  >
                    <Switch location={location}>
                      <Route exact path="/" component={Homepage} />

                      <Route path="/didacticiel" component={Tutorial} />

                      <PrivateRoute path="/test-tonal" component={TestTonal} />

                      <PrivateRoute path="/test-comprehension" component={TestUnderstand} />

                      <PrivateRoute path="/resultats" component={Results} />
                    </Switch>
                  </CSSTransition>
                </TransitionGroup>
              </TransitionBlock>
            </ThemeProvider>
          )}
        />
      </React.Fragment>
    );
  }
}

App.propTypes = {
  tutoDone: PropTypes.bool.isRequired,
  tonalsDone: PropTypes.bool.isRequired,
  understandsDone: PropTypes.bool.isRequired,
  step: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  // location: PropTypes.object.isRequired,
};

export default withRouter(connect(state => ({
  tutoDone: state.tutoDone,
  tonalsDone: state.tonals.tonalsDone,
  understandsDone: state.understands.understandsDone,
  step: state.step,
}))(App));
