import React from 'react';
import styled, { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import Steps from 'components/Steps';
import steps from 'utils/Steps';

const Block = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  position: relative;
  padding-top: 7.5rem;
`;

const BlockTop = styled.div`
  width: 100%;
  display: flex;
  flex-flow: wrap column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 25px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 0;

`;

const Noise = styled.p`
  text-align: center;
  text-transform: uppercase;
  font-size: 0.9rem;
  margin: 20px 0 10px;
`;

const TestUnderstandBlock = ({
  children, nb,
  // loader,
}) => (
  <Block>
    {/* <Wave player autoplay /> */}
    <BlockTop>
      <Noise>{steps.getUnderstandText(nb)}</Noise>
      <Steps nbSteps={4} nb={nb} />
    </BlockTop>
    {children}
    {/* {loader ? <Wave /> : <div />} */}
  </Block>
);

TestUnderstandBlock.propTypes = {
  children: PropTypes.node.isRequired,
  nb: PropTypes.number.isRequired,
  // loader: PropTypes.bool.isRequired,
};


export default withTheme(TestUnderstandBlock);
