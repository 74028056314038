import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import media from 'utils/styledMixins';

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 98;
  background-color: rgba(11, 15, 33, .95);
`;

const StyledRestoreModal = styled.div`
  width: 90%;
  max-width: 600px;
  height: 250px;

  position: fixed;
  border-radius: 5px;
  top: 50%;
  left: 50%;
  z-index: 99;
  padding: 60px;
  background-color: #ffffff;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  padding: 20px 5px;

  ${media.tablet`
    height: 200px;
    padding: 0;
  `}
`;

const StyledButton = styled(Button)`
  color: #000000;
  border-color: #000;
`;

const Choices = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  button {
    margin: 10px 0;
  }

  ${media.tablet`
    flex-direction: row;
  `}
`;

const Paragraph = styled.p`
  color: #515151;
  padding: 0 15px;
`;

const RestoreModal = ({ emptyAll, closeModal }) => (
  <React.Fragment>
    <Overlay />
    <StyledRestoreModal>
      <Paragraph>
        Vous avez un test en cours,
        <br />
        voulez vous le continuer ou recommencer ?
      </Paragraph>
      <Choices>
        <Button onClick={closeModal}>Continuer</Button>
        <StyledButton transparent onClick={emptyAll}>Recommencer le test</StyledButton>
      </Choices>
    </StyledRestoreModal>
  </React.Fragment>
);

RestoreModal.propTypes = {
  emptyAll: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default RestoreModal;
