import React from 'react';
import Lottie from 'react-lottie';
import PropTypes from 'prop-types';
import Wave from 'components/Wave';
import Button from 'components/Button';
import styled, { withTheme } from 'styled-components';
import media from 'utils/styledMixins';
// import { TransitionGroup, CSSTransition } from 'react-transition-group';
import animationData from './data.json';

const StyledTuto = styled.div`
  width: 100vw;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 98;

  svg {
    transform: scale(3);

    ${media.tablet`
      transform: scale(1);
    `}
  }
`;

const Background = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(6, 8, 28, 0.95) ;

  svg {
    transform: translate3d(0, 0, 0) scale(4) !important;

    ${media.tablet`
      transform: translate3d(0, 0, 0) !important;
    `}
  }
`;

const StyledButton = styled.div`
  width: 100%;
  box-sizing: border-box;
  position: absolute;
  top: 70%;
  left: 50%;
  padding: 0 30px;
  text-align: center;
  transform: translate(-50%, -20%);
`;

// const LoadingMessage = styled.span`
//   position: fixed;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
// `;

const Paragraph = styled.p`
  padding-bottom: 35px;
`;

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
};

const TutoAnim = ({ onClick }) => (
  <StyledTuto>
    <Wave player light />
    <Background>
      <Lottie
        width="100%"
        options={defaultOptions}
      />
      <StyledButton>
        <Paragraph>Déplacez le curseur de gauche à droite</Paragraph>
        <Button onClick={onClick}>{ 'J\'ai compris' }</Button>
      </StyledButton>
    </Background>
  </StyledTuto>
);

TutoAnim.propTypes = {
  onClick: PropTypes.func.isRequired,
};

// TutoAnim.defaultProps = {
//   player: false,
// };

export default withTheme(TutoAnim);
