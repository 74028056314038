
export const SET_STEP_TONAL = 'SET_STEP_TONAL';
export const SET_STEP_UNDERSTAND = 'SET_STEP_UNDERSTAND';

function setStepTonal(step) {
  return {
    type: SET_STEP_TONAL,
    payload: {
      stepTonal: step,
    },
  };
}

function setStepUnderstand(step) {
  return {
    type: SET_STEP_UNDERSTAND,
    payload: {
      stepUnderstand: step,
    },
  };
}

export function stepTonalHandler(step) {
  localStorage.setItem('stepTonal', JSON.stringify(step));
  return setStepTonal(step);
}

export function stepUnderstandHandler(step) {
  localStorage.setItem('stepUnderstand', JSON.stringify(step));
  return setStepUnderstand(step);
}
