import React from 'react';
import { Helmet } from 'react-helmet';
import Button from 'components/Button';
import PropTypes from 'prop-types';
import Bell from 'components/Icons/Bell';
import styled from 'styled-components';
import SubTitle from 'components/Texts/SubTitle';

const TutorialIconBell = styled(Bell)`
  width: 20px;
  fill: #ffffff;
  padding-top: 1.5rem;
`;

const Paragraph = styled.p`
  font-weight: 200;
  max-width: 250px;
  text-align: center;
  padding: 0.5rem 0 1.8rem;
  font-size: 1rem;

  b {
    font-weight: 600;
  }
`;

const TutorialIntro = ({ onClick }) => (
  <React.Fragment>
    <Helmet>
      <title>Avant de commencer... | Entendre</title>
    </Helmet>
    <SubTitle>Avant de commencer...</SubTitle>
    <TutorialIconBell />
    <Paragraph>
      Veuillez vous isoler dans une pièce
      <b> au calme</b>
      .
    </Paragraph>
    <Button color="green" onClick={e => onClick('appareil', e)}>Je suis prêt(e)</Button>
  </React.Fragment>
);

TutorialIntro.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default TutorialIntro;
