
import { createStore, combineReducers, applyMiddleware } from 'redux';

import createHistory from 'history/createBrowserHistory';

import { routerReducer, routerMiddleware } from 'react-router-redux';

import reducers from '../reducers'; // Or wherever you keep your reducers

const history = createHistory();
// history.listen(location => ReactGA.track(location.pathname));
// Build the middleware for intercepting and dispatching navigation actions
const middleware = routerMiddleware(history);
const dataLayer = [];
const analytics = () => next => (action) => {
  window.dataLayer = window.dataLayer || [];
  dataLayer.push({
    event: action.type,
    payload: action.payload,
  });

  return next(action);
};
// Add the reducer to your store on the `router` key
// Also apply our middleware for navigating
const store = createStore(
  combineReducers({
    ...reducers,
    router: routerReducer,
  }),
  applyMiddleware(middleware, analytics),
);

export { store, history };
