import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import PropTypes from 'prop-types';
import Routes from 'utils/Routes';
import { Howl } from 'howler';
import platform from 'platform';
import { stepUnderstandHandler } from 'actions/steps';
import { setResultUnderstand, setUnderstandFinish } from 'actions/understands';
import Main from 'containers/partials/Main';
import entendreHomeFilter from 'images/entendreHomeFilter.jpg';
import { Route, Switch } from 'react-router';
// import AudiosParams from './AudiosParams';
import testContent from './TestContent';

import TestUnderstandIntro from './TestUnderstandIntro';
import TestUnderstandPlayer from './TestUnderstandPlayer';

const getLevelRef = (sound) => {
  const os = platform.os.family;
  switch (os) {
    case 'Android':
    case 'Windows Phone':
      return sound.android;

    case 'iOS':
      return sound.ios;

    default:
      return sound.desktop;
  }
};

const loadSound = soundData => (new Promise((resolve) => {
  const howlFile = new Howl({
    src: [soundData.file],
    loop: false,
  });
  howlFile.on('load', () => {
    const sound = {
      howl: howlFile,
      levelRef: getLevelRef(soundData),
    };
    resolve(sound);
  });
}));

const loadAllSound = async () => {
  const data1 = await loadSound(testContent[0]);
  const data2 = await loadSound(testContent[1]);
  const data3 = await loadSound(testContent[2]);
  const data4 = await loadSound(testContent[3]);

  return [data1, data2, data3, data4];
};

class TestUnderstand extends React.Component {
  state = {
    dataReady: [],
    loaded: false,
  }


  componentWillMount() { // TODO : Force user to continue the test
    const { stepUnderstand } = this.props;
    this.handleRoute(stepUnderstand);
  }


  componentDidMount() {
    loadAllSound().then((data) => {
      this.setState({
        loaded: true,
        dataReady: data,
      });
    });
  }

  handleRoute = (step) => {
    const { dispatch } = this.props;
    const route = Routes.getRouteUnderstand(step);
    dispatch(push(route));
  }

  handleClick = (newStep) => {
    const { dispatch } = this.props;

    this.handleRoute(newStep);
    if (newStep === true) {
      dispatch(setUnderstandFinish());
    } else {
      dispatch(stepUnderstandHandler({ nb: newStep.nb }));
    }
  }

  handleResults = (newStep, response) => {
    const { dispatch, stepUnderstand } = this.props;
    // const { dataReady } = this.state;

    dispatch(setResultUnderstand(stepUnderstand.nb, response)); //eslint-disable-line
    this.handleClick(newStep);
  }

  render() {
    const { stepUnderstand } = this.props;
    const {
      dataReady,
      loaded,
    } = this.state;
    return (
      <React.Fragment>
        <Helmet>
          <title>{`Test de compréhension ${stepUnderstand.nb} | Entendre`}</title>
        </Helmet>
        <Main noFooter backImg={entendreHomeFilter} backImgMobile={entendreHomeFilter}>
          <Switch>
            <Route strict path="/test-comprehension/intro" render={() => <TestUnderstandIntro loaded={loaded} onClick={this.handleClick} />} />
            <Route
              strict
              path="/test-comprehension/:id"
              render={() => (
                <TestUnderstandPlayer
                  data={dataReady}
                  loaded={loaded}
                  onClick={this.handleResults}
                  nb={stepUnderstand.nb}
                />)}
            />
          </Switch>
        </Main>
      </React.Fragment>
    );
  }
}

TestUnderstand.propTypes = {
  dispatch: PropTypes.func.isRequired,
  stepUnderstand: PropTypes.object.isRequired,
};

export default connect(state => ({
  stepUnderstand: state.step.stepUnderstand,
}))(TestUnderstand);
