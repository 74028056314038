import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import TurningPhone from 'components/Icons/TurningPhone';
// const SyledModal = styled(Modal)`

// `;

Modal.setAppElement('#root');

const StyledTurningPhone = styled(TurningPhone)`
  width: 50px;
  height: 50px;
  padding-top: 25px;
  fill: #37b5a7;
`;

const Paragraph = styled.p`
  text-align: center;
  padding: 0 30px;
`;
class OrientationModal extends React.Component {
  state = {
    isMobileLandscape: (screen.height < screen.width) && screen.width < 800, // eslint-disable-line
  }

  componentDidMount() {
    window.addEventListener('resize', () => {
      console.log(screen.width); // eslint-disable-line
      this.setState({
        isMobileLandscape: (screen.height < screen.width) && screen.width < 800, // eslint-disable-line
      });
    });
  }

  render() {
    const { modalClassName, className, ...props } = this.props;
    const { isMobileLandscape } = this.state;
    return (
      <Modal
        isOpen={isMobileLandscape}
        contentLabel="Example Modal"
        className={modalClassName}
        portalClassName={className}
        {...props}
      >
        <Paragraph>
          Pour bénéficier d’une meilleure expérience utilisateur,
          merci de passer votre téléphone en mode portrait
        </Paragraph>
        <StyledTurningPhone />
      </Modal>
    );
  }
}

const StyledModal = styled(OrientationModal).attrs({
  overlayClassName: 'Overlay',
  modalClassName: 'Modal',
})`
  .Modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    position: fixed;
    width: 70%;
    height: 95vh;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    overflow: scroll;
    z-index: 6;
    background-color: #FFFFFF
  }
  .Overlay {
    top: 0;
    left: 0;
    position: fixed;
    z-index: 5;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
  }
`;

OrientationModal.propTypes = {
  className: PropTypes.string.isRequired,
  modalClassName: PropTypes.string.isRequired,
};

export default StyledModal;
