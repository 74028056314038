import React from 'react';

const Cross = props => (
  <svg viewBox="0 0 612 612" {...props}>
    <title>20x20/Cross</title>
    <path d="M336.3 306L605.7 36.6c8.4-8.4 8.4-21.9 0-30.3-8.4-8.4-21.9-8.4-30.3 0L306 275.7 36.6 6.3c-8.4-8.4-21.9-8.4-30.3 0-8.4 8.4-8.4 21.9 0 30.3L275.7 306 6.3 575.4c-8.4 8.4-8.4 21.9 0 30.3 4.2 4.2 9.7 6.3 15.2 6.3s11-2.1 15.2-6.3L306 336.3l269.4 269.4c4.2 4.2 9.7 6.3 15.2 6.3s11-2.1 15.2-6.3c8.4-8.4 8.4-21.9 0-30.3L336.3 306z" />
  </svg>
);

export default Cross;
