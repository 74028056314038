import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledPartner = styled.a`
  display: flex;
  flex-flow: wrap row;
  justify-content: center;
  align-items: center;
  width: 225px;
  height: 100px;
  padding: 15px 25px;
  margin-right: 20px;
  margin-bottom: 20px;
  background: #ffffff;
  box-sizing: border-box;
`;

const StyledPartnerImg = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const Partner = ({ img, link, name }) => (
  <StyledPartner href={link} target="_blank" rel="noreferrer nooopener">
    <StyledPartnerImg src={img} alt={name} />
  </StyledPartner>
);

Partner.propTypes = {
  img: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default Partner;
