import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import media from 'utils/styledMixins';

const StyledInfosList = styled.ul`
  display: flex;
  flex-flow: wrap row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 40px 0;

  ${media.tablet`
    margin: 90px 0;
  `}
`;

const InfosList = ({ children }) => (
  <StyledInfosList>
    {children}
  </StyledInfosList>
);

InfosList.propTypes = {
  children: PropTypes.node.isRequired,
};

export default InfosList;
