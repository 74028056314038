import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import media from 'utils/styledMixins';

const StyledInfosItem = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 5px;

  ${media.tablet`
    margin: 0 50px;
  `}
`;

const InfosItem = ({ children }) => (
  <StyledInfosItem>
    {children}
  </StyledInfosItem>
);

InfosItem.propTypes = {
  children: PropTypes.node.isRequired,
};

export default InfosItem;
