import React from 'react';
import styled from 'styled-components';
import media from 'utils/styledMixins';

const Block = styled.div`
 padding: 100px 60px 50px 50px;
`;


const Title = styled.h1`
  font-size: 2rem;
  font-weight: 600;
  color: #424242;
  padding-bottom: 32px;

  span {
    color: #45B7A7;
  }
`;

const Subtitle = styled.h2`
  font-weight: 600;
  color: #424242;
  padding-top: 16px;
  padding-bottom: 32px;
`;
const Paragraph = styled.p`
  font-size: 1rem;
  color: #868686;
  padding-bottom: 32px;

  ${media.tablet`
    padding-right: 100px;
  `}
`;

const SectionTitle = styled.h3`
  font-weight: 400;
  color: #424242;
  padding-bottom: 16px;
`;

const Link = styled.a`
  color: #45B7A7;
`;

const Mentions = () => (
  <Block>
    <Title>
Mentions
      <span> légales</span>
    </Title>
    <Subtitle>Préambule</Subtitle>
    <Paragraph>Le propriétaire et/ou éditeur est une personne morale.</Paragraph>

    <Paragraph>
      Propriétaire et/ou éditeur du site :
      Entendre SAS TVA Intra communautaire : FR04481855419
      {' '}
      <br />
      Siège social : 2 BIS RUE FRANCISCO FERRER – 78210 SAINT CYR L’ECOLE
      <br />
      Tél : 01 30 07 17 87
      <br />
      Fax : 01 30 07 17 89
    </Paragraph>

    <Paragraph>
      Directeur de la publication :
      ENTENDRE SAS (ou directrice d’enseigne Fabienne DESABRES LEPAGE)
    </Paragraph>

    <Paragraph>Conception / Hébergement : de medicis SAS Boulogne Billancourt</Paragraph>

    <Subtitle>Loi informatique et libertés</Subtitle>
    <Paragraph>
      {`
        Le Site Entendre est déclaré auprès de la Commission Nationale Informatique et
        Libertés (CNIL) en application de la loi n°78-17 du 6 janvier 1978 relative à
        l'informatique, aux fichiers et aux libertés. Chaque internaute ayant déposé des
        informations nominatives le concernant sur le Site dispose des droits d'opposition
        (article 38 de la loi), d'accès (articles 39, 41 et 42 de la loi) et de rectification
        (article 40 de la loi) de ces données. Ainsi, il peut exiger que soient rectifiées,
        complétées, clarifiées, mises à jour ou effacées les informations le concernant qui sont inexactes,
        incomplètes, équivoques, périmées, ou dont la collecte ou l'utilisation, la communication ou
        la conservation est interdite. Chaque internaute peut exercer ces droits en écrivant au siège social d’Entendre.

        `}
    </Paragraph>
    <Paragraph>
      {
        `
        Entendre s’engage auprès de vous afin que vos données personnelles vous soient accessibles,
        consultables et rectifiables à tout instant, selon le nouveau règlement européen sur la protection des données personnelles, RGPD.

        Vos données sont donc traitées par la société à laquelle votre audioprothésiste est rattaché.
        Elles sont destinées à assurer le suivi de votre appareillage et, si vous l’avez signifié,
        à des fins commerciales. Vos données seront conservées jusqu’à 5 ans après votre dernière prise de contact avec votre audioprothésiste Entendre,
        dans le cas où vous ne vous seriez pas manifesté pour supprimer vos données.
        `
      }
    </Paragraph>
    <Paragraph>
      {`
        Chaque internaute est informé que les données le concernant
        pourront être utilisées par Entendre à des fins de prospection
        directe pour des produits ou services Entendre.
      `}
    </Paragraph>

    <Paragraph>
      {`
        Afin de mieux vous servir, nous mesurons le nombre de pages vues,
        le nombre de visites, ainsi que l'activité des internautes sur le Site,
        et leur fréquence de retour.
      `}
    </Paragraph>

    <Paragraph>
      {`
        A cet effet, nous utilisons la technologie des "cookies", ceux-ci sont émis par le serveur de la société.
        Vous pouvez refuser ces cookies ou les supprimer sans que cela ait une quelconque influence sur votre navigation dans le site.
        Vous pouvez vous opposer à l'enregistrement de cookies ou être prévenu avant d'accepter les cookies, en configurant votre ordinateur
        de la manière suivante : l'utilisateur procèdera au paramétrage de son navigateur à partir du menu « outils »
        pour Microsoft Internet Explorer, Firefox, Google Chrome, Safari.
      `}
    </Paragraph>

    <Paragraph>
      Les fonctionnalités de Google Analytics pour les annonceurs
      display sont activées pour sur ce site (Remarketing).
      {' '}
      <br />
      Google utilise des cookies pour diffuser nos annonces sur les
      sites de son réseau Display.
      {' '}
      <br />
      Grâce au cookie DoubleClick, Google adapte les annonces diffusées aux
      utilisateurs en fonction de leur navigation sur notre site.
      {' '}
      <br />
      Vous pouvez désactiver
      {' '}
      {"l'utilisation de cette fonctionnalité en vous rendant sur"}
&nbsp;
      <Link href="https://www.google.com/settings/u/0/ads" target="_blank" rel="noopener noreferrer">le gestionnaire de préférences pour les annonces</Link>
.
    </Paragraph>

    <Subtitle>Propriété Intellectuelle</Subtitle>

    <SectionTitle>
Droits
      {"d'auteur"}
    </SectionTitle>

    <Paragraph>
      La totalité des éléments du site testauditif.entendre.com,
      notamment les textes, présentations, illustrations,
      photographies, arborescences et mises en forme sont,
      sauf documents publics et précisions complémentaires,
      la propriété intellectuelle exclusive de la société Entendre ou de ses partenaires.
    </Paragraph>

    <Paragraph>
      A ce titre, leurs représentations, reproductions, imbrications, diffusions et rediffusions,
      partielles ou totales, sont interdites conformément aux dispositions du Code de la propriété
      intellectuelle. Toute personne y procédant sans pouvoir justifier
      {' '}
      {"d'une autorisation"}
      {' '}
préalable
      et expresse du détenteur de ces droits
      encourt les peines relatives au délit de contrefaçon prévues au
      Code de la propriété intellectuelle.
    </Paragraph>

    <Paragraph>
      En outre, les représentations, reproductions,
      imbrications, diffusions et rediffusions, partielles ou
      totales, de la base de données contenue dans le
      site testauditif.entendre.com sont interdites en vertu
      des dispositions du Code de la propriété intellectuelle.
    </Paragraph>

    <Paragraph>
      En tout état de cause, sur toute copie autorisée
      de tout ou partie du contenu du site, devra figurer la mention
      « Copyright 2018 Entendre tous droits réservés ».
    </Paragraph>

    <SectionTitle>Marques</SectionTitle>

    <Paragraph>
      La marque Entendre est une marque déposée du groupe Entendre.
      A ce titre, toute personne procédant à leurs représentations, reproductions, imbrications,
      diffusions et rediffusions encourt les sanctions prévues au
      Code de la propriété intellectuelle.
    </Paragraph>
  </Block>
);

export default Mentions;
