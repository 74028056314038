import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route } from 'react-router';
import { push } from 'react-router-redux';
import { finishTuto } from 'actions/tuto';
import styled from 'styled-components';

import entendreHomeFilter from 'images/entendreHomeFilter.jpg';
import entendreHomeFilterMobile from 'images/entendreHomeFilterMobile.jpg';

import Main from 'containers/partials/Main';

import TutorialIntro from './TutorialIntro';
import TutorialDevice from './TutorialDevice';
import TutorialAudio from './TutorialAudio';


const StyledTutorial = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  color: #ffffff;
  padding: 4.5rem 0;
`;

class TutorialContainer extends React.Component { //eslint-disable-line

  handleClick = (step) => {
    const { dispatch } = this.props;
    dispatch(push(`/didacticiel/${step}`));
  }

  goToTest = () => {
    const { dispatch } = this.props;
    dispatch(push('/test-tonal/intro'));
    dispatch(finishTuto());
  }

  render() {
    // const { router } = this.props;
    // const { location } = router;
    return (
      <Main backImg={entendreHomeFilter} backImgMobile={entendreHomeFilterMobile}>
        <StyledTutorial>
          <Route exact path="/didacticiel/intro" render={() => <TutorialIntro onClick={this.handleClick} />} />
          <Route exact path="/didacticiel/appareil" render={() => <TutorialDevice onClick={this.handleClick} />} />
          <Route exact path="/didacticiel/audio" render={() => <TutorialAudio onClick={this.goToTest} />} />
        </StyledTutorial>
      </Main>
    );
  }
}

TutorialContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const Tutorial = connect(state => ({
  router: state.router,
}))(TutorialContainer);

export default Tutorial;
