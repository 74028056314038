import React from 'react';

const VolumeIcon = props => (
  <svg
    width={475.082}
    height={475.081}
    viewBox="0 0 475.082 475.081"
    {...props}
  >
    <path d="M200.999 63.952c-4.946 0-9.229 1.812-12.847 5.426l-95.074 95.075H18.276c-4.952 0-9.234 1.812-12.85 5.424C1.809 173.493 0 177.778 0 182.726v109.63c0 4.949 1.809 9.233 5.426 12.848 3.619 3.617 7.902 5.427 12.85 5.427h74.798l95.074 95.078c3.617 3.61 7.9 5.424 12.847 5.424 4.952 0 9.234-1.813 12.85-5.424 3.617-3.614 5.426-7.901 5.426-12.847V82.228c0-4.948-1.809-9.234-5.422-12.85-3.619-3.614-7.898-5.426-12.85-5.426zM316.769 277.936c8.093-12.467 12.135-25.93 12.135-40.395s-4.042-27.992-12.135-40.556c-8.094-12.562-18.791-21.41-32.121-26.551-1.902-.949-4.284-1.427-7.139-1.427-4.944 0-9.232 1.762-12.847 5.282-3.61 3.521-5.427 7.852-5.427 12.99 0 3.997 1.143 7.376 3.432 10.137 2.283 2.762 5.041 5.142 8.282 7.139a147.75 147.75 0 0 1 9.708 6.567c3.238 2.38 5.996 5.758 8.278 10.135 2.276 4.38 3.426 9.804 3.426 16.277 0 6.471-1.143 11.896-3.426 16.276-2.282 4.381-5.04 7.755-8.278 10.14a147.75 147.75 0 0 1-9.708 6.567c-3.241 1.992-5.999 4.377-8.282 7.132-2.282 2.765-3.432 6.143-3.432 10.14 0 5.144 1.816 9.47 5.427 12.99 3.614 3.521 7.902 5.288 12.847 5.288 2.854 0 5.236-.479 7.139-1.424 13.33-5.339 24.031-14.24 32.121-26.707z" />
    <path d="M377.728 318.194c16.18-24.646 24.273-51.531 24.273-80.654 0-29.124-8.094-56.005-24.273-80.666-16.177-24.645-37.6-42.583-64.241-53.815-2.471-.95-4.948-1.427-7.416-1.427-4.948 0-9.236 1.809-12.854 5.426-3.613 3.616-5.424 7.898-5.424 12.847 0 7.424 3.713 13.039 11.139 16.849 10.657 5.518 17.888 9.705 21.693 12.559 14.089 10.28 25.077 23.173 32.976 38.686 7.898 15.514 11.848 32.026 11.848 49.537 0 17.512-3.949 34.023-11.848 49.536-7.898 15.516-18.894 28.407-32.976 38.684-3.806 2.857-11.036 7.043-21.693 12.563-7.426 3.809-11.139 9.424-11.139 16.847 0 4.948 1.811 9.236 5.424 12.847 3.617 3.621 7.991 5.432 13.131 5.432 2.286 0 4.668-.483 7.139-1.428 26.641-11.234 48.064-29.173 64.241-53.823z" />
    <path d="M438.824 116.92c-24.171-36.638-56.343-63.622-96.505-80.943-2.471-.95-4.948-1.425-7.416-1.425-4.948 0-9.236 1.811-12.847 5.424-3.621 3.615-5.432 7.902-5.432 12.85 0 6.851 3.714 12.469 11.14 16.846 1.335.756 3.467 1.755 6.42 2.996 2.95 1.232 5.089 2.231 6.427 2.993 8.754 4.755 16.56 9.611 23.418 14.56 23.407 17.318 41.682 38.922 54.816 64.809 13.134 25.885 19.697 53.388 19.697 82.512 0 29.129-6.563 56.626-19.697 82.512-13.131 25.889-31.409 47.496-54.816 64.809-6.858 4.948-14.664 9.801-23.418 14.562-1.338.756-3.477 1.752-6.427 2.99-2.953 1.232-5.085 2.231-6.42 2.998-7.426 4.374-11.14 9.993-11.14 16.844 0 4.949 1.811 9.233 5.432 12.848 3.61 3.617 7.898 5.427 12.847 5.427 2.468 0 4.945-.476 7.416-1.431 40.162-17.315 72.334-44.3 96.505-80.94 24.174-36.638 36.258-76.849 36.258-120.625s-12.081-83.982-36.258-120.616z" />
  </svg>
);

export default VolumeIcon;
