import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
// ${props => props.primary ? 'palevioletred' : 'white'};
import media from 'utils/styledMixins';

const StyledButton = styled.button`
  position: relative;
  z-index: 2;
  border: 0;
  padding: 15px 20px;
  border-radius: 50px;
  font-size: 1rem;
  font-weight: 300;
  cursor: pointer;
  transform: scale(1);
  transition: transform .2s ease;
  outline: none;

  &:hover {
    transform: scale(1.1);
  }

  ${media.phone`
    padding: 15px 30px;
  `}

  ${media.tablet`
    font-size: .7rem;
  `}
`;

const DisabledButton = StyledButton.extend`
  background-color: ${props => (props.transparent ? 'transparent' : props.theme.ebony)};
  border: ${props => (props.transparent ? '1px solid rgba(255, 255, 255, 0.1)' : 0)};
  color:  rgba(255, 255, 255, 0.1);
  cursor: not-allowed;

  svg {
    fill: rgba(255, 255, 255, 0.1);
  }
`;

const ColoredButton = StyledButton.extend`
  background-color: ${props => (props.transparent ? 'transparent' : props.theme.main)};
  border: ${props => (props.transparent ? '1px solid rgba(255, 255, 255, 0.2)' : 0)};
  color: #ffffff;
`;

const Button = ({
  children, onClick, disabled, transparent, className,
}) => (
  disabled ? (
    <DisabledButton transparent={transparent} disabled className={className}>
      {children}
    </DisabledButton>
  )
    : (
      <ColoredButton onClick={onClick} transparent={transparent} className={className}>
        {children}
      </ColoredButton>
    )
);


Button.propTypes = {
  transparent: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  disabled: false,
  transparent: false,
  className: '',
};
export default withTheme(Button);
