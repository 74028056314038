
export const DEFINE_EAR_DEVICE = 'DEFINE_EAR_DEVICE';


export function setEarDevice(earDevice) {
  localStorage.setItem('earDevice', earDevice);
  return {
    type: DEFINE_EAR_DEVICE,
    payload: {
      earDevice,
    },
  };
}
