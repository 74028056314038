import { injectGlobal, keyframes } from 'styled-components';
import reset from 'styled-reset';
import media from 'utils/styledMixins';

const moveEnter = keyframes`
  from { transform: translateX(100%); }
  to { transform: translateX(0); }
`;

const moveLeave = keyframes`
  from { transform: translateX(0); }
  to { transform: translateX(-100%); }
`;

const baseStyles = () => injectGlobal`
  ${reset}

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    font-size: 14px;

    ${media.tablet`
      font-size: 18px
    `}
    ${media.desktop`
      font-size: 20px
    `}
  }

  body,
  button {
    font-family: 'Ubuntu', sans-serif;
  }

  p,
  h2 {
    line-height: 1.4;
  }

  p {
    font-weight: 200;
  }


  .black {
    color: rgba(0, 0, 0, 0.5);
  }
  /* .fade-enter {
    opacity: 0;
  }

  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 1000ms ease-in;
  }

  .fade-exit {
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
  }

  .fade-exit.fade-exit-active {
    opacity: 0;
    transition: opacity 800ms ease-in;
  } */

  .slide-enter {
    transform: translateX(100%);
  }

  .slide-enter.slide-enter-active {
    transform: translateX(0);
    animation: ${moveEnter} .6s ease both;
  }

  .slide-exit {
    transform: translate3d(0);
    position: absolute;
    top: 0;
    left: 0;
  }

  .slide-exit.slide-exit-active {
    transform: translateX(-100%);
    animation: ${moveLeave} .6s ease both;
  }

  .fade-exit {
    opacity: 0.01;
  }

  .fade-exit.fade-exit-active {
    opacity: 1;
    animation: opacity .3s ease both;
  }

  .fade-exit {
    opacity: 1;
  }

  .fade-exit.fade-exit-active {
    opacity: 0.01;
    animation: opacity .3s ease both;
  }


  /* other styles */
`;

export default baseStyles;
