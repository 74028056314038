import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';

import ReplayIcon from 'components/Icons/VolumeIcon';
import Button from 'components/Button';
import steps from 'utils/Steps';
import SelectList from 'components/Select/SelectList';
import SelectItem from 'components/Select/SelectItem';
import SubTitle from 'components/Texts/SubTitle';
import media from 'utils/styledMixins';
import soundUtils from 'utils/Sound';
import testContent from './TestContent';
import TestUnderstandBlock from './TestUnderstandBlock';

const StyledReplayIcon = styled(ReplayIcon)`
  width: 25px;
  height: 100%;
  padding-left: 15px;
  fill: ${props => props.theme.white};
`;

const Replay = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
`;

const Choices = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  max-width: 450px;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 25%;

  ${media.tablet`
    margin-bottom: 10%;
  `}

  button {
    margin: 10px 0;
  }
`;
class TestUnderstandPlayer extends React.Component { //eslint-disable-line
  state = {
    playing: false,
    response: null,
    played: false,
  }

  componentDidMount() {
    const {
      data, nb, testDone, loaded,
    } = this.props;
    const { playing } = this.state;

    if (loaded && !testDone && !playing && data[nb - 1].howl) {
      this.changeVolume(data[nb - 1], 40);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      data, nb, testDone, loaded,
    } = this.props;
    const { playing } = this.state;
    if (!testDone && (nb - 1 in data)
    && !playing
    && (nb !== prevProps.nb || loaded !== prevProps.loaded)) {
      this.changeVolume(data[nb - 1], 40);
    }
    console.log(data[nb - 1]);
  }


  componentWillUnmount() {
    const { data, nb } = this.props;
    this.stopMusic(data[nb - 1]);
  }

  getReponses(nb) {
    const { response } = this.state;
    return testContent[nb - 1].responses.map((value, key) => (
      <SelectItem
        key={value.name}
        selected={response === key}
        value={value.name}
        onClick={e => this.handleReponse(key, e)}
      />));
  }

  resetSound() {
    this.setState({
      response: null,
    });
  }

  playMusic(sound) {
    if (!sound.howl.playing()) {
      sound.howl.play();
      sound.howl.on('end', () => {
        this.setState({
          playing: false,
        });
      });
    }
    this.setState({
      played: true,
      playing: true,
    });
  }

  stopMusic(sound) {
    sound.howl.stop();
    this.setState({
      playing: false,
    });
  }


  changeVolume(sound, value) { //eslint-disable-line
    const db = value;
    const volume = soundUtils.DBToFloat(sound.levelRef, db);
    sound.howl.volume(volume);
  }

  handleReponse(value) {
    this.setState({
      response: value,
    });
  }

  validateResponse() {
    const {
      nb, onClick, data,
    } = this.props;
    const { response } = this.state;
    const newStep = steps.getNewStepUnderstand(testContent.length, nb);
    this.stopMusic(data[nb - 1]);
    this.resetSound();
    onClick(newStep, response);
    if (newStep !== true) {
      this.changeVolume(data[nb], 40);
      this.playMusic(data[nb]);
    }
  }

  handlePlayMusic() {
    const { data, nb } = this.props;
    this.playMusic(data[nb - 1]);
  }

  handleStopMusic() {
    const { data, nb } = this.props;
    this.stopMusic(data[nb - 1]);
  }

  render() {
    const {
      nb,
    } = this.props;

    const { response, played, playing } = this.state;
    return (
      <TestUnderstandBlock
        nb={nb}
      >
        <SubTitle>{testContent[nb - 1].question}</SubTitle>
        <SelectList>
          {this.getReponses(nb)}
        </SelectList>
        <Choices>
          <Button transparent disabled={playing} onClick={e => this.handlePlayMusic(e)}>
            <Replay>
              {played ? 'Rejouer ' : 'Jouer '}
              le son
              <StyledReplayIcon />
            </Replay>
          </Button>
          <Button disabled={response === null} onClick={e => this.validateResponse(e)}>
            Je valide
          </Button>
        </Choices>
      </TestUnderstandBlock>
    );
  }
}

TestUnderstandPlayer.propTypes = {
  onClick: PropTypes.func.isRequired,
  nb: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired,
  testDone: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
};

export default withTheme(connect(state => ({
  testDone: state.understands.understandsDone,
}))(TestUnderstandPlayer));
