import React from 'react';
import PropTypes from 'prop-types';
import DiscussionIcon from 'components/Icons/SpeakerInterfaceAudioSymbol';
import Button from 'components/Button';
import SubTitle from 'components/Texts/SubTitle';
// import Wave from 'components/Wave';

import styled, { withTheme } from 'styled-components';
// import TestTonalBlock from './TestTonalBlock';
const Block = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 4em;
  padding-bottom: 2em;
  text-align: center;
  color: ${props => props.theme.white};
`;

const StyledDiscussionIcon = styled(DiscussionIcon)`
  width: 30px;
  height: auto;
  fill: ${props => props.theme.main};
`;

const Paragraph = styled.p`
  max-width: 500px;
  padding: 50px 0;
`;

const TopHead = styled.p`
  text-transform: uppercase;
  font-size: 0.9rem;
  margin: 20px 0 10px;
`;

const TestUnderstandIntro = ({ loaded, onClick }) => (
  <Block>
    <StyledDiscussionIcon />
    <TopHead>Test de Compréhension</TopHead>
    <SubTitle padding="35px 0 0">Il est temps d’évaluer votre audition du point de vue de la compréhension.</SubTitle>
    <Paragraph>
      Il s’agit de mesurer, dans un premier temps, votre perception du langage dans le
      calme puis dans un environnement plus bruyant.
    </Paragraph>
    <Button disabled={!loaded} color="green" onClick={e => onClick({ nb: 1 }, e)}>Je commence le test de compréhension</Button>
  </Block>
);

TestUnderstandIntro.propTypes = {
  onClick: PropTypes.func.isRequired,
  loaded: PropTypes.bool.isRequired,
};

export default withTheme(TestUnderstandIntro);
