import Android from 'components/Schemas/AndroidMobile';
import Ios from 'components/Schemas/IosMobile';

const InfosMobile = [
  {
    name: 'Android smartphone',
    text: `
      Utilisez les boutons sur le côté de votre appareil pour régler le volume jusqu'à ce que le curseur se trouve au milieu de la barre de volume.
    `,
    icon: Android,
  }, {
    name: 'Apple iPhone',
    text: `
      Utilisez les boutons sur le côté gauche de votre iPhone pour régler le volume jusqu'à ce que la jauge de volume soit remplie à moitié.
    `,
    icon: Ios,
  },
];

export default InfosMobile;
