import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ReplayIcon from 'components/Icons/VolumeIcon';
import TutoAnim from 'components/TutoAnim';
import Button from 'components/Button';
import media from 'utils/styledMixins';
import styled from 'styled-components';
import steps from 'utils/Steps';
import soundUtils from 'utils/Sound';
import TestTonalBlock from './TestTonalBlock';

const ButtonBlock = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 50%;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;

  button:first-child {
    margin-bottom: 15px;
  }

  ${media.tablet`
    flex-flow: row wrap;

    button:first-child {
        margin-right: 20px;
        margin-bottom: 0;
      }
  `}
`;

const Play = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
`;

const StyledReplayIcon = styled(ReplayIcon)`
  width: 25px;
  height: 100%;
  padding-left: 15px;
  fill: ${props => props.theme.white};
`;

class TestTonalPlayer extends React.Component { //eslint-disable-line
  state = {
    playing: false,
    tutoOpen: true,
    level: 50,
  }

  componentDidMount() {
    // const {
    //   data,
    //   nb,
    //   testDone,
    //   loaded,
    // } = this.props;
    // const { playing, tutoOpen } = this.state;
    // if (loaded && !testDone && !tutoOpen && !playing && data[nb - 1].howl) {
    // }
  }

  componentDidUpdate(prevProps) {
    const {
      testDone, data, nb, hear,
    } = this.props;
    const {
      tutoOpen, playing,
    } = this.state;
    if (!testDone && !tutoOpen && !playing && data[nb - 1].howl) {
      this.stereoHandler(data[nb - 1]);
      // this.playMusic(data[nb - 1]);
    }
    if (prevProps.hear !== hear) {
      this.playMusic(data[nb - 1]);
    }
    if (prevProps.nb !== nb || prevProps.hear !== hear) {
      this.resetSound();
    }
  }

  componentWillUnmount() {
    const { data, nb } = this.props;
    this.stopMusic(data[nb - 1]);
  }

  changeVolume(sound, value) {
    const { loaded } = this.props;
    const db = value * 0.4;
    const volume = soundUtils.DBToFloat(sound.levelRef, db);
    if (loaded) {
      this.setState({
        level: value,
      });
      sound.howl.volume(volume);
    }
  }


  stereoHandler(sound) {
    const { hear } = this.props;
    if (hear === 'left') {
      sound.howl.stereo(-1);
    } else {
      sound.howl.stereo(1);
    }
  }


  resetSound() {
    this.setState({
      level: 50,
    });
  }

  playMusic(sound) {
    const { level } = this.state;
    if (!sound.howl.playing()) {
      this.changeVolume(sound, level);
      this.stereoHandler(sound);
      sound.howl.play();
    }
    this.setState({
      playing: true,
    });
  }

  goToNextStep() {
    const { level } = this.state;
    const {
      hear, nb, onClick, data,
    } = this.props;
    const newStep = steps.getNewStepTonal(hear, nb);
    this.stopMusic(data[nb - 1]);
    this.resetSound();
    const DB = Math.round(level * 0.4);
    onClick(newStep, DB);
    if (newStep !== true && newStep.hear === hear) {
      this.playMusic(data[nb]);
    }
  }

  stopMusic(sound) {
    sound.howl.stop();
    this.setState({
      playing: false,
    });
  }

  handleChangeVolume(e) {
    const { data, nb } = this.props;
    this.changeVolume(data[nb - 1], e);
  }

  handlePlayMusic() {
    const { data, nb } = this.props;
    this.playMusic(data[nb - 1]);
  }

  handleStopMusic() {
    const { data, nb } = this.props;
    this.stopMusic(data[nb - 1]);
  }

  handleClose() {
    const { data, nb } = this.props;
    this.setState({
      tutoOpen: false,
    });
    this.playMusic(data[nb - 1]);
  }

  render() {
    const {
      hear,
      nb,
      loaded,
    } = this.props;

    const {
      level, tutoOpen, playing,
    } = this.state;

    return (
      <React.Fragment>
        <Helmet>
          <title>{`Test tonal des sons ${steps.getStepText(nb)} sur l’oreille ${hear} | Entendre`}</title>
        </Helmet>
        <TestTonalBlock
          loader={!loaded}
          volumeController
          level={level}
          changeVolume={e => this.handleChangeVolume(e)}
          hear={hear}
          nb={nb}
        >
          {hear === 'right' && nb === 1 && tutoOpen ? <TutoAnim onClick={e => this.handleClose(e)} /> : ''}
          <ButtonBlock>
            <Button
              transparent
              onClick={e => (playing ? this.handleStopMusic(e) : this.handlePlayMusic(e))
              }
            >
              <Play>
                {playing ? 'Arrêter ' : 'Jouer '}
                le son
                <StyledReplayIcon />
              </Play>
            </Button>
            <Button disabled={!loaded} color="green" onClick={e => this.goToNextStep(e)}>{`J'entends les sons ${steps.getStepText(nb)}`}</Button>
          </ButtonBlock>
        </TestTonalBlock>
      </React.Fragment>
    );
  }
}

TestTonalPlayer.propTypes = {
  onClick: PropTypes.func.isRequired,
  nb: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired,
  hear: PropTypes.string.isRequired,
  loaded: PropTypes.bool.isRequired,
  testDone: PropTypes.bool.isRequired,
};

export default connect(state => ({
  testDone: state.tonals.tonalsDone,
}))(TestTonalPlayer);
