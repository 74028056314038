import React from 'react';

import entendre from 'images/entendre.png';
import styled from 'styled-components';
import media from 'utils/styledMixins';

const StyledHeader = styled.header`
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 30px;
  padding: 10px 0;
  background-color: #ffffff;

  ${media.tablet`
    height: 45px;
    padding: 10px 0;
  `}
`;

const HeaderImage = styled.img`
  height: 100%;
`;


const Header = () => (
  <StyledHeader>
    <HeaderImage className="header__image" src={entendre} alt="Entendre" />
  </StyledHeader>
);

export default Header;
