
export const CLEAR = 'CLEAR';

function emptyAll() {
  return {
    type: CLEAR,
  };
}

export function emptyAllAction() {
  localStorage.clear();
  sessionStorage.clear();
  return emptyAll();
}
