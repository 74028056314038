import React from 'react';
import styled from 'styled-components';
// import PropTypes from 'prop-types';
import media from 'utils/styledMixins';
import Partner from 'components/Partners/Partner';
import partners from 'components/Partners/PartnersData';

const StyledPartnersContainer = styled.div`
  padding: 40px 0;
  background-color: #ededed;
`;

const StyledPartnersInner = styled.div`
  max-width: 1120px;
  padding: 15px;
  margin: 0 auto;
`;

const StyledPartnersTitle = styled.h2`
  color: #464646;
  font-size: 35px;
  text-align: left;
`;

const StyledPartnersList = styled.div`
  display: flex;
  flex-flow: wrap row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 25px 0;

  ${media.tablet`
  justify-content: flex-start;
    margin: 50px 0;
  `}
`;


const PartnersList = () => (
  <StyledPartnersContainer>
    <StyledPartnersInner>
      <StyledPartnersTitle>Nos Partenaires</StyledPartnersTitle>
      <StyledPartnersList>
        {
          partners.map((elem, index) => {
            const key = index;
            return <Partner key={key} img={elem.img} link={elem.href} name={elem.name} />;
          })
        }
      </StyledPartnersList>
    </StyledPartnersInner>
  </StyledPartnersContainer>
);


export default PartnersList;
