import React from 'react';
import PropsTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
// import StepsIcon from 'components/Icons/Steps';

import media from 'utils/styledMixins';

const StyledSteps = styled.div`

`;

const StepsList = styled.ul`
  width: ${props => (props.nbSteps === 3 ? '100px' : '140px')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin: 5px 0 20px;
  transform: scale(0.5); //TODO: change this to pixel when have time
  ${media.tablet`
    transform: scale(0.8);
  `}

   &::before {
    width: calc(100% - 35px);
    height: 5px;
    content: '';
    position: absolute;
    top: 7.5px;
    left: 20px;
    z-index: 0;
    background-color: ${props => props.theme.white};
  }

   &::after {
    width: 0%;
    height: 5px;
    content: '';
    position: absolute;
    top: 7.5px;
    left: 15px;
    z-index: 1;
    background-color: ${props => props.theme.main};
    transition: width .5s linear;
  }

  &.step1::after {
    width: 0%;
  }

  &.step2::after {
    width: ${props => (props.nbSteps === 3 ? 'calc(50% - 25px)' : 'calc(38.33% - 25px)')} ;
  }

  &.step3::after {
    width: ${props => (props.nbSteps === 3 ? 'calc(100% - 35px)' : 'calc(76.66% - 35px)')} ;
  }

  &.step4::after {
    width: ${props => (props.nbSteps === 3 ? 'calc(100% - 25px)' : 'calc(100% - 34px)')} ;
  }

`;

const StepsItem = styled.li`
  width: 20px;
  height: 20px;
  content: '';
  display: block;
  text-align: center;
  border-radius: 50%;
  background-color: ${props => props.theme.white};
  overflow: hidden;
  position: relative;

  &:first-child {
    background-color: ${props => props.theme.main};
  }

  &::after {
    width: 105%;
    height: 105%;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-100%);
    background-color: ${props => props.theme.main};
    transition: transform .5s linear .5s;
  }

  &.active::after {
    transform: translate(0);
  }

`;
// const StyledStepsIcon = styled(StepsIcon)`
//   width: 400px;
// `;

// const Rect = styled.div`
//   background-color: ${props => props.theme.main};
//   height: 200px;
//   clip-path: url(#cross);
//   transition: width .3s ease;
// `;

const Steps = ({ nbSteps, nb }) => (
  <StyledSteps>
    <StepsList nbSteps={nbSteps} className={`step${nb}`}>
      <StepsItem className={nb >= 1 ? 'active' : ''} />
      <StepsItem className={nb >= 2 ? 'active' : ''} />
      <StepsItem className={nb >= 3 ? 'active' : ''} />
      {nbSteps === 4 ? <StepsItem className={nb >= 4 ? 'active' : ''} /> : ''}
    </StepsList>
  </StyledSteps>
);

Steps.propTypes = {
  nbSteps: PropsTypes.number.isRequired,
  nb: PropsTypes.number.isRequired,
};

export default withTheme(Steps);
