import file500 from 'audios/500Hz.m4a';
import file1000 from 'audios/1000Hz.m4a';
import file2000 from 'audios/2000Hz.m4a';

const files = [
  {
    file: file500,
    desktop: {
      headphones: 83.45,
      earphones: 83.45,
    },
    ios: {
      headphones: 49.45,
      earphones: 49.45,
    },
    android: {
      headphones: 61.8,
      earphones: 61.8,
    },
  },
  {
    file: file1000,
    desktop: {
      headphones: 89.45,
      earphones: 89.45,
    },
    ios: {
      headphones: 57.45,
      earphones: 59.45,
    },
    android: {
      headphones: 68.45,
      earphones: 68.45,
    },
  },
  {
    file: file2000,
    desktop: {
      headphones: 79.15,
      earphones: 79.15,
    },
    ios: {
      headphones: 63.45,
      earphones: 67.45,
    },
    android: {
      headphones: 54.15,
      earphones: 54.15,
    },
  },
];

export default files;
