import React from 'react';
// We can just import Slider or Range to reduce bundle size
import PropTypes from 'prop-types';
import Slider, { createSliderWithTooltip } from 'rc-slider';
import Arrow from 'components/Icons/ArrowPointToRight';
import styled from 'styled-components';
import media from 'utils/styledMixins';


const percentFormatter = v => `${v} %`;

const SliderWithTooltip = createSliderWithTooltip(Slider);

const StyledVolumeController = styled.div`
  width: 94vw;
  height: 100vh;
  position: fixed;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  ${media.tablet`
    top: 58%;
  `}


  /* .rc-slider-track {
    width: 5px;
    height: 100%;
  } */

  .rc-slider-handle {
    width: 70px;
    height: 70px;
    position: absolute;
    background-color: transparent;
    cursor: pointer;
    cursor: grab;
    touch-action: pan-x;
    border-radius: 50%;
    top: 50%;
    transform: translate(-35px, -50%);
    outline: none;
    z-index: 5;

    &:focus {
      box-shadow: 0 0 7px 5px rgba(55, 181, 167, .5);
    }

  ${media.phone`
    top: 50%;
  `}
  ${media.tablet`
    width: 100px;
    height: 100px;
    transform: translate(-50px, -50%);
  `}
  ${media.giant`
    top: 50%;
  `}
  }

`;

const BarLevel = styled.span`
  position: absolute;
  background-color: #696e86;
  width: 2px;
  height: 97%;
  top: 0;
  opacity: 0.2;
  z-index:0;
`;

const ButtonVolumeBlock = styled.div`
  width: 70px;
  position: absolute;
  top: 50%;
  padding-left: 1px;
  transform: translate(-50%, -50%);
  transition: transform .05s ease;
  z-index: 0;
  background-color: #ffffff;
  border-radius: 50%;
  border: 0;
  ${media.phone`
    top: 50%;
  `}
  ${media.tablet`
    width: 100px;
  `}
  ${media.giant`
    top: 50%;
  `}
`;

const ButtonVolume = styled.button`
  width: 35px;
  height: 70px;
  border-radius: 50px 0 0 50px;
  border: 0;

  ${media.tablet`
    width: 50px;
    height: 100px;
  `}

  &:hover {
    background-color: ${props => props.theme.whiteGrey};
  }
`;

const StyledArrow = styled(Arrow)`
  position: relative;
  width: 15px;
  margin-top: 3px;
  transform: rotate(-180deg);
`;

const ButtonVolumeLeft = ButtonVolume.extend`

`;

const ButtonVolumeRight = ButtonVolume.extend`
  transform: rotate(-180deg);
`;

const VolumeController = ({ changeVolume, level, animState }) => {
  const buttonTransition = {
    entering: { transform: 'scale(0) translateX(-50%, -50%)' },
    entered: { transform: 'scale(1) translateX(-50%, -50%)' },
    exiting: {
      transform: 'scale(1) translateX(-50%, -50%)',
      left: `${level}%`,
    },
    exited: {
      transform: 'scale(0) translateX(-50%, -50%)',
      left: `${level}%`,
    },
  };
  return (
    <StyledVolumeController>
      <SliderWithTooltip
        tipFormatter={percentFormatter}
        tipProps={{ overlayClassName: 'foo' }}
        onChange={changeVolume}
        value={level}
      />
      <BarLevel style={{ left: `${level}%` }} />
      <ButtonVolumeBlock style={{ left: `${level}%`, ...buttonTransition[animState] }}>
        <ButtonVolumeLeft>
          <StyledArrow />
        </ButtonVolumeLeft>
        <ButtonVolumeRight>
          <StyledArrow />
        </ButtonVolumeRight>
      </ButtonVolumeBlock>
    </StyledVolumeController>
  );
};


VolumeController.propTypes = {
  level: PropTypes.number.isRequired,
  changeVolume: PropTypes.func.isRequired,
  animState: PropTypes.string,
};

VolumeController.defaultProps = {
  animState: '',
};

export default VolumeController;
