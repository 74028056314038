
export const RIGHT_HEAR = 'RIGHT_HEAR';
export const LEFT_HEAR = 'LEFT_HEAR';
export const SET_FINISH_TONAL = 'SET_FINISH_TONAL';

function setLeftResult(step, result) {
  return {
    type: LEFT_HEAR,
    payload: {
      step,
      result,
    },
  };
}

function setRightResult(step, result) {
  return {
    type: RIGHT_HEAR,
    payload: {
      step,
      result,
    },
  };
}

function finishTestTonal() {
  return {
    type: SET_FINISH_TONAL,
    payload: {
      tonalsDone: true,
    },
  };
}

export function setResultTonal(hear, step, result) {
  if (hear === 'left') {
    return setLeftResult(step, result);
  }
  return setRightResult(step, result);
}

export function setTonalFinish() {
  return finishTestTonal();
}
