import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { setEarDevice } from 'actions/earDevice';
import Button from 'components/Button';
import SubTitle from 'components/Texts/SubTitle';

import TutorialButton from 'components/TutorialButton';
import InfosList from 'components/Infos/InfosList';
import InfosItem from 'components/Infos/InfosItem';
import Headphones from 'components/Icons/Headphones';
import Earphones from 'components/Icons/Earphones';

const StyledHeadphones = styled(Headphones)`
  width: 1.5rem;
  height: 100%;
  padding: 0 20px;
  color: ${props => props.theme.white};
  fill: ${props => props.theme.white};
`;

const StyledEarphones = styled(Earphones)`
  width: 1.5rem;
  height: 100%;
  padding: 0 20px;
  color: ${props => props.theme.white};
  fill: ${props => props.theme.white};
`;

class TutorialAudio extends React.Component {
  state = {
    device: -1,
  }

  handleClick = (device) => {
    this.setState({
      device,
    });
  }

  setUserEarDevice = () => {
    const { device } = this.state;
    const { onClick, dispatch } = this.props;

    switch (device) {
      case 0:
        dispatch(setEarDevice('headphones'));
        break;
      default:
        dispatch(setEarDevice('earphones'));
        break;
    }
    onClick();
  }

  render() {
    const { device } = this.state;
    return (
      <React.Fragment>
        <Helmet>
          <title>Indiquez si vous portez un casque ou des écouteurs | Entendre</title>
        </Helmet>
        <SubTitle maxWidth="1000px">
          Indiquez si vous disposez d’un casque audio ou
          d’écouteurs et portez-le(s) à vos oreilles.
        </SubTitle>
        <InfosList>
          <InfosItem>
            <TutorialButton selected={device === 0} onClick={e => this.handleClick(0, e)}>
              <StyledHeadphones />
            </TutorialButton>
          </InfosItem>
          <InfosItem>
            <TutorialButton selected={device === 1} onClick={e => this.handleClick(1, e)}>
              <StyledEarphones />
            </TutorialButton>
          </InfosItem>
        </InfosList>
        <Button color="green" disabled={device === -1} onClick={this.setUserEarDevice}>Je suis prêt(e)</Button>
      </React.Fragment>
    );
  }
}

TutorialAudio.propTypes = {
  onClick: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};


export default connect()(TutorialAudio);
