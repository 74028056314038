
import storage from 'utils/Storage';

let newState = {};
const tonals = (state = {
  results: storage.getFromStorageObject('tonals', 'results'),
  tonalsDone: storage.getFromStorageObject('tonals', 'tonalsDone'),
}, action) => {
  switch (action.type) {
    case 'RIGHT_HEAR':
      newState = Object.assign({}, state, {
        ...state,
        results: {
          ...state.results,
          right: {
            ...state.results.right,
            [action.payload.step]: action.payload.result,
          },
        },
      });
      localStorage.setItem('tonals', JSON.stringify(newState));
      return newState;
    case 'LEFT_HEAR':
      newState = Object.assign({}, state, {
        ...state,
        results: {
          ...state.results,
          left: {
            ...state.results.left,
            [action.payload.step]: action.payload.result,
          },
        },
      });
      localStorage.setItem('tonals', JSON.stringify(newState));
      return newState;
    case 'SET_FINISH_TONAL':
      newState = Object.assign({}, state, {
        results: { ...state.results },
        tonalsDone: action.payload.tonalsDone,
      });
      localStorage.setItem('tonals', JSON.stringify(newState));
      return newState;
    case 'CLEAR':
      return {
        results: {},
        tonalsDone: false,
      };
    default:
      return state;
  }
};

export default tonals;
