import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router';
import PropTypes from 'prop-types';

const PrivateRouteContainer = ({ tutoDone, component: Component, ...props }) => (
  <Route
    {...props}
    render={propsR => (
      tutoDone
        ? <Component {...propsR} />
        : (
          <Redirect to={{
            pathname: '/didacticiel/intro',
          }}
          />
        ))
    }
  />
);

PrivateRouteContainer.propTypes = {
  tutoDone: PropTypes.bool.isRequired,
  component: PropTypes.func.isRequired,
};

const PrivateRoute = connect(state => ({
  tutoDone: state.tutoDone,
}))(PrivateRouteContainer);

export default PrivateRoute;
